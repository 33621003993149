import React from "react";
import { Button, Flex, useMantineTheme } from "@mantine/core";
import Actions from "src/components/Actions";
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import LayoutHandler from "../../../../classes/ui/LayoutHandler";

interface JobOfferActionsProps {
    isPublic: boolean;
    actionSubmit: any;
    isOwn: boolean;
    id: string;
}

const JobOfferActions: React.FC<JobOfferActionsProps> = ({
                                                             isPublic,
                                                             actionSubmit,
                                                             isOwn,
                                                             id,
                                                         }) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const lh = LayoutHandler.getInstance();
    let is_mobile = lh.isMobileDevice();





    const handleButtonClick = () => {
        if (isPublic) {
            confirmAlert({
                title: actionSubmit.confirmTitle,
                message: actionSubmit.confirmMessage,
                buttons: [
                    {
                        label: t(actionSubmit.confirmButtonConfirm),
                        onClick: () => {
                            window.location.href = actionSubmit.to;
                        },
                        style: {
                            backgroundColor: theme.colors.primary[6],
                            color: "white",
                            borderRadius: "4px",
                            margin: "10px",
                        },
                    },
                    {
                        label: t("cancel").charAt(0).toUpperCase() + t("cancel").slice(1),
                        onClick: () => {},
                        style: {
                            backgroundColor: theme.colors.gray[4],
                            color: "white",
                            margin: "10px",
                            borderRadius: "4px",
                        },
                    },
                ],
            });
        } else {
            window.location.href = actionSubmit.to;
        }
    };

    return (
        <div
            style={
                is_mobile
                    ? {
                        position: "fixed",
                        bottom: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "100%",
                        backgroundColor: "white",
                        padding: "10px",
                        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        justifyContent: "center",
                    }
                    : {}
            }
        >

            {is_mobile? ( <div>
                <br/>
                <br/>
                <br/>
            </div>):<></>}
            <Flex justify="center" align="center" style={{ height: "6vh" }}>
                {isPublic ? (
                    <Button variant="filled" onClick={handleButtonClick}>
                        {t(actionSubmit.label)}
                    </Button>
                ) : (
                    <>

                    <Actions
                        actions={[
                            isOwn
                                ? { label: "edit", to: `/app/edit/job/${id}` }
                                : actionSubmit,
                        ]}
                    />
                    </>
                )}
            </Flex>
        </div>
    );
};

export default JobOfferActions;
