import { useLocation } from 'react-router-dom';
import SimpleTable from "./SimpleTable";
import Proposal from "src/types/Proposal.type";
import ProposalTableRow from "./rows/ProposalTable.Row";
import usePageView from "../../hooks/usePageView";

interface Props {
  data?: Proposal[];
  mode?: string
}


const cols = ["to_employee", "job_offer", "desc", "status"];
/*
const col_titles = ["to_employee", "job_offer_sentx", "first_contact", "status"];
*/
/*
Next go with this structure
const colsProps = {
  to_employee: {
    field_name: "to_employee",
    field_title: "To Employee",
    data_type: "string",
    is_required: true,
  },
  job_offer: {
    field_name: "job_offer",
    field_title: "Job Offer",
    data_type: "string",
    is_required: true,
  },
  desc: {
    field_name: "desc",
    field_title: "Description",
    data_type: "text",
    is_required: false,
  },
  status: {
    field_name: "status",
    field_title: "Status",
    data_type: "string",
    is_required: true,
  },
};
*/

function ProposalTable({ data = [] , mode }: Props) {

  const location = useLocation();

  let col_titles = ["to_employee", "job_offer_sent", "first_contact", "status"];

  usePageView("proposal_list");
  if(location.pathname==="/app/section/proposals_received") {
    col_titles = ["to_employee", "job_offer_received", "first_contact", "status"];
  }
  return (
    <SimpleTable
      section="proposal"
      cols={cols}
      col_titles={col_titles}
      rows={data}
      Row={ProposalTableRow}
      readOnly
      mode={mode}
    />
  );
}

export default ProposalTable;
