import { useEffect, ReactNode } from "react";
import AppRouter from "../classes/AppRouter";
import Application from "../classes/Application";

interface RedirectCheckProps {
    children: ReactNode;
}
export const RedirectCheck = ({ children }: RedirectCheckProps) => {
    useEffect(() => {
        const isAuth = Application.getInstance().isAuth();

        if (isAuth) {
            const currentPath = window.location.pathname;

            if (currentPath.startsWith("/public")) {
                const redirectedPath = currentPath.replace("/public", "");
                AppRouter.getInstance().navigateTo(redirectedPath);
            } else {
                AppRouter.getInstance().navigateTo("/"); // Default redirect to /app
            }
        }
    }, []);

    return <>{children}</>;
};
