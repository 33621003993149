import React, { useState } from "react";
import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import { ConfirmDialogParams } from "../ConfirmDialog/ConfirmDialogParams";
import { useTranslation } from "react-i18next";
import "./RequiredCompanyConfirmDialog.css";
import appRouter from "../../../../classes/AppRouter";

interface RequiredCompanyConfirmDialogProps {
    params: ConfirmDialogParams;
    isOpen?: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

const RequiredCompanyConfirmDialog: React.FC<RequiredCompanyConfirmDialogProps> = ({
                                                                                       params,
                                                                                       isOpen: externalIsOpen,
                                                                                       onConfirm,
                                                                                       onCancel
                                                                                   }) => {
    const { t } = useTranslation();
    const [internalIsOpen, setInternalIsOpen] = useState(true);

    // Use either the external isOpen prop or the internal state
    const isOpen = externalIsOpen ?? internalIsOpen;

  //  alert(t("insert_your_company"));
    // Set default params if not set
    params.setTitle(t("required_company"));
    params.setMessage(t("to_perform_this_action_requires_company"));
    params.setConfirmButtonLabel(t("insert_your_company"));
    params.setCancelButtonLabel(t("refuse_company_insert"));

    params.setOnConfirm(()=> {
        let fun = params.getCloseModal();


        if(fun) {
            fun();
        }
        let rtr = appRouter.getInstance();
        rtr.setNextToUrl();
        rtr.setCancelToUrl();
        rtr.navigateTo("/company/register");
    });

    // Handle the confirm action
    const handleConfirm = () => {
        onConfirm?.();
        setInternalIsOpen(false); // Close the modal after confirmation
    };

    // Handle the cancel action
    const handleCancel = () => {
        onCancel?.();
        setInternalIsOpen(false); // Close the modal after cancellation
    };

    return <ConfirmDialog params={params} isOpen={isOpen} onClose={handleCancel} />;
};

export default RequiredCompanyConfirmDialog;
