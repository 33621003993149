import { useNavigate } from "react-router-dom";
import AdminForm from "src/components/forms/Admin.form";
import useApi from "src/hooks/useApi";
import Admin from "src/types/Admin.type";
import usePageView from "../../../hooks/usePageView";
import TrackingSystem from "../../../lib/Tracking/TrackingSystem";

export default function AddAdmin() {
  const navigate = useNavigate();
  const API = useApi();

  const onSubmit = async (values: Partial<Admin>, file?: File) => {

    usePageView("form_admin");
    if (file) {
      const res = await API.UPLOAD(file);
      values.profilePic = res.name;
    }
    const res = await API.POST(`res/user`, values);
    let ts = TrackingSystem.getInstance().sendCustomEvent("admin_added");
    if (res?.success) {
      navigate("/app/section/admins");
    }
  };

  return <AdminForm title="create_admin" onSubmit={onSubmit} />;
}
