import {Box, Divider, Group, Paper, Stack} from "@mantine/core";
import CompanyContacts from "src/components/CompanyContacts";
import Company from "src/types/Company.type";
import CompanySubscription from "../CompanySubscription";
import CardButtonLinks from "../CompanyLinks";
import ActionButton from "../ActionButton";
import companyLinks from "src/constants/companyLinks.const";
import CompanySection from "../Sections/Company.Section";
import SeeMore from "../SeeMore";
import useIsConnected from "src/hooks/useIsConnected";
import useIsOwn from "src/hooks/useIsOwn";
import Error from "src/pages/Error";
import useIsMobile from "src/hooks/useIsMobile";
import Config from "../../classes/application/Config";
import CardButtonLinksPublic from "../CompanyLinksPublic";

interface Props {
    company?: Company;
}

export default function CompanyView({company}: Props) {
    const isMobile = useIsMobile();
    const isOwn = useIsOwn(company);
    const isConnected = useIsConnected(company);
    const cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl();

    if (isPublic) {
        return (
            <>
                <CardButtonLinksPublic links={companyLinks}/>
            </>
        );
    }

    if (!company) {

        let cfg = Config.getInstance();

        if (!cfg.hasProgressiveRegister()) {
            return <Error title="approval_req" desc="approval_req_desc"/>;
        }
        /**
         * @todo define what to do there ,invire completion?
         */
        return <>

            <Box maw={isMobile ? "auto" : 1000} mx="auto" pb={isMobile ? "xl" : "xs"}>
                {/* Top section */}
                <Group justify="right" my="md">
                </Group>
                <Paper mx="xs" className="tour_start">

                </Paper>
                <Divider my="lg"/>

                {/* Bottom section */}
                <Group align="top" wrap="wrap">
                    {/* Company button links */}
                    {isOwn ? (
                        <Stack
                            w={isMobile ? "80%" : "57%"}
                            miw={isMobile ? "none" : "380"}
                            mx="auto"
                        >
                            <CardButtonLinks links={companyLinks}/>
                        </Stack>
                    ) : (
                        <SeeMore isOwn={isOwn} isConnected={isConnected} type="company"/>
                    )}

                    {/* Contacts and sub info */}
                    <Stack
                        w={isMobile ? "97%" : "28%"}
                        miw={isMobile ? "none" : "380"}
                        mx="auto"
                    >
                    </Stack>
                </Group>
            </Box>

        </>;

        //
    }

    return (
        <>
            <Box maw={isMobile ? "auto" : 1000} mx="auto" pb={isMobile ? "xl" : "xs"}>
                {/* Top section */}
                <Group justify="right" my="md">
                    {isOwn && (
                        <ActionButton
                            variant="default"
                            to={`/app/edit/company/${company.id}`}
                            label={"edit_profile"}
                            isReady
                        />
                    )}
                </Group>
                <Paper mx="xs" className="tour_start">
                    <CompanySection company={company}/>
                </Paper>
                <Divider my="lg"/>

                {/* Bottom section */}
                <Group align="top" wrap="wrap">
                    {/* Company button links */}
                    {isOwn ? (
                        <Stack
                            w={isMobile ? "80%" : "57%"}
                            miw={isMobile ? "none" : "380"}
                            mx="auto"
                        >
                            <CardButtonLinks links={companyLinks}/>
                            <CompanySubscription company={company}/>
                        </Stack>
                    ) : (
                        <SeeMore isOwn={isOwn} isConnected={isConnected} type="company"/>
                    )}

                    {/* Contacts and sub info */}
                    <Stack
                        w={isMobile ? "97%" : "28%"}
                        miw={isMobile ? "none" : "380"}
                        mx="auto"
                    >
                        <Paper m="0">
                            <CompanyContacts company={company} isMatched={isConnected}/>
                        </Paper>
                    </Stack>
                </Group>
            </Box>
        </>
    );
}
