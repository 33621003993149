import Cookies from 'js-cookie';

class Config {
    private static instance: Config;
    private env;
    private readonly env_original;

    // private settings:any;

    private constructor(env: any) {
        if (env !== null) {
            this.env = env;
            this.env_original = env;
        }
    }


    public isJubendaEnabled(): boolean {
        return false;
    }

    public isCurrentPublicUrl(): boolean {
        const currentPath = window.location.pathname;
        const publicUrls = this.getPublicUrls();

        // Check if the current path matches any of the public URLs
        return publicUrls.some((publicUrl) => currentPath.startsWith(publicUrl));
    }

    public isCurrentSearchAuthenticatedUrl(): boolean {
        const currentPath = window.location.pathname;
        const publicUrls = this.getSearchAuthUrls();

        // Check if the current path matches any of the public URLs
        return publicUrls.some((publicUrl) => currentPath.startsWith(publicUrl));
    }

    public hasProgressiveRegister(): boolean {

        console.log("Chek progressive" + this.isEnvDevelopment());
     //   return false;
        if (this.isEnvDevelopment()) {
            return true;
        }
        return false;

    }


    public getPublicUrls(): string[] {
        const publicRoutes = [
            "/public/view/employee",

            "/public/view/jobs",
            "/public"
        ];
        return publicRoutes;
    }

    public getSearchAuthUrls(): string[] {
        const publicRoutes = [
            "/app/view/employee",
            "/app/view/job",
        ];
        return publicRoutes;
    }


    public static getInstance(env = null): Config {
        if (!Config.instance) {
            Config.instance = new Config(env);
        }
        return Config.instance;
    }

    public isApiTest(): boolean {
        return this.getApiUrl() != this.env.VITE_API_URL;
    }

    public getLanguage() {
        return window.localStorage.getItem("i18nextLng");
    }

    public setLanguage(lng: string) {
        return window.localStorage.setItem("i18nextLng", lng);
    }

    public hasInfoBoxesEnabled(): boolean {

        return true;
    }

    public isEnvDevelopment(): boolean {

        /*
          alert(1);
          alert(JSON.stringify(this.env));
          alert(import.meta.env.VITE_APP_ENV)
          alert(this.env.VITE_APP_ENV)
          */
        if (!this.env.VITE_APP_ENVN) {
            return false;
        }
        return this.env.VITE_APP_ENVN === "development";
    }

    public isEnvProduction(): boolean {


        if (!this.env.VITE_APP_ENVN) {
            return true;
        }
        return this.env.VITE_APP_ENVN === "production";
    }

    public getEnv(): string {
        if (!this.env.VITE_APP_ENV) {
            return "production";
        }
        return this.env.VITE_APP_ENV
    }

    public hasPublicAreaEnabled(): boolean {

        if (localStorage.getItem("publicarea") === "true") {
            return true;
        }
        return false;
    }

    public hasFeatureCredits(): boolean {
        return this.env.VITE_FEATURE_CREDITS === "true";
    }

    public hasFeatureProgressiveRegistration(): boolean {
        if (!this.env.VITE_FEATURE_PROGRESSIVE_REGISTRATION) {
            return false;
        }
        return this.env.VITE_FEATURE_PROGRESSIVE_REGISTRATION === "true";
    }

    public isUrlConfig(): boolean {

        return location.href.indexOf("app/ik4kf884ax9") > 0
    }

    public getEnvOriginal() {
        return this.env_original;
    }

    public getApiUrl(): string {

        const stored_url = this.retrieveApiUrl();


        if (!stored_url) {
            return this.env.VITE_API_URL
        }

        if (!stored_url?.trim()) {
            return this.env.VITE_API_URL
        }
        return stored_url;

    }

    public storeApiUrl(apiUrl: string): void {
        localStorage.setItem('a8api_url', apiUrl);
    }

    public storeAi(aic: string): void {
        Cookies.set("ai_cc", aic, {expires: 300});
    }

    public getAi(): string {
        const aic: string = Cookies.get("ai_cc") || "";
        return aic;
    }

    public retrieveApiUrl(): string | null {
        return localStorage.getItem('a8api_url');
    }
}

export default Config;