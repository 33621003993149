import {
  IconArrowsExchange,
  IconBuildingSkyscraper,
  IconChartBar,
  IconHeartHandshake,
  IconHelp,
  IconList,
  IconListSearch,
  IconMailbox,
  IconReport,
  IconSchool,
  IconSend,
  IconSettings,
  IconSpeakerphone,
  IconUserSearch,
  IconUsers,
  IconMessageCircle
} from "@tabler/icons-react";


const iconMaps: { [key: string]: JSX.Element } = {
  company: <IconBuildingSkyscraper />,
  job: <IconReport />,
  employee: <IconUsers />,
  dashboard: <IconChartBar />,
  support: <IconHelp />,
  settings: <IconSettings />,
  notifications: <IconSpeakerphone />,
  history: <IconList />,
  matches: <IconArrowsExchange />,
  talent_hunting: <IconMailbox />,
  job_hunting: <IconSend />,
  tutorial: <IconSchool />,
  applications: <IconUsers />,
  proposals: <IconHeartHandshake />,
  searchEmployee: <IconUserSearch />,
  searchJob: <IconListSearch />,
  inviteRequest: <IconMailbox />,
  chatbot: <IconMessageCircle />,
};

export default iconMaps;
