import { ArrayAccessor } from "../lib/ArrayAccessor";

export class EmployeeModel extends ArrayAccessor {
    public get_id(): string {
        return this.get("id");
    }

    public set_id(id: string): void {
        this.set("id", id);
    }

    public get_languages(): string[] {
        return this.get("languages");
    }

    public set_languages(languages: string[]): void {
        this.set("languages", languages);
    }

    public get_fields(): string[] {
        return this.get("fields");
    }

    public set_fields(fields: string[]): void {
        this.set("fields", fields);
    }

    public get_zones(): string[] {
        return this.get("zones");
    }

    public set_zones(zones: string[]): void {
        this.set("zones", zones);
    }

    public get_desc(): string {
        return this.get("desc");
    }

    public set_desc(desc: string): void {
        this.set("desc", desc);
    }

    public get_visa(): string {
        return this.get("visa");
    }

    public set_visa(visa: string): void {
        this.set("visa", visa);
    }

    public get_skills(): any {
        return this.get("skills") || [];
    }

    public countSkills():number {

        try {
            let count = this.get_skills().length;
            return count;

        }
        catch (e)
        {

        }
        return 0;
     }
    public set_skills(skills: string[]): void {
        this.set("skills", skills);
    }

    public get_certifications(): string[] {
        return this.get("certifications");
    }

    public set_certifications(certifications: string[]): void {
        this.set("certifications", certifications);
    }

    public get_training(): string {
        return this.get("training");
    }

    public set_training(training: string): void {
        this.set("training", training);
    }

    public get_residentialCity(): string {
        return this.get("residentialCity") || "N/A";
    }

    public set_residentialCity(residentialCity: string): void {
        this.set("residentialCity", residentialCity);
    }

    public get_residentialNation(): string {
        return this.get("residentialNation") || "N/A";
    }

    public set_residentialNation(residentialNation: string): void {
        this.set("residentialNation", residentialNation);
    }
}
