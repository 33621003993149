import {Flex, Group, NavLink} from "@mantine/core";
import {
    IconBarcode,
    IconBrandWhatsapp,
    IconLink,
    IconMail,
    IconMapPin,
    IconPhone,
} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";
import Company from "src/types/Company.type";
import CompanySocials from "./cards/CompanySocials";
import {Link} from "react-router-dom";
import "./CompanyContacts.css";

interface Props {
    company: Company;
    isMatched: boolean;
}


const CompanyContacts = (props: Props) => {
    const {company, isMatched} = props;
    const {t} = useTranslation();

    function capitalizeFirstChar(str: string) {
        if (!str) return str; // handle empty string or null
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    function fixUrl(url: string): string {
        /*
              // Trim whitespace from both ends and remove any internal spaces
              let fixedUrl = url.trim().replace(/\s+/g, '');

              // Ensure the URL starts with "https://"
              if (fixedUrl.startsWith('https:')) {
                  // If 'https:' is found but not 'https://', add the slashes
                  if (fixedUrl.length > 5 && fixedUrl[5] !== '/') {
                      fixedUrl = 'https://' + fixedUrl.substring(5); // Add slashes after 'https:'
                  }
              } else if (!fixedUrl.startsWith('https://')) {
                  fixedUrl = 'https://' + fixedUrl; // Add "https://" if it's missing
              }
      */
        const elem = document.createElement('a');
        elem.href = url;

        return elem.href;
    }


    //alert(fixUrl(company.contact_website.toLowerCase()));


    if (!isMatched) {
        return null;
    }
    const {loc_city, loc_prov, loc_zip, loc_address, loc_num} = company;
    const fullAddr = [loc_city, loc_prov, loc_zip, loc_address, loc_num]
        .filter((e) => e)
        .join();

    return (
        <Flex direction="column">
            <NavLink
                leftSection={<IconBarcode/>}
                description={t("vat")}
                label={company.vat}
            />
            {company.contact_website && (
                <NavLink
                    className="lowercase-link"
                    component={Link}
                    to={company.contact_website}
                    leftSection={<IconLink/>}
                    description={capitalizeFirstChar(t("website"))}
                    label={fixUrl(company.contact_website.toLowerCase())}
                    style={{textTransform: 'lowercase'}}
                    tt="none"

                />
            )}
            {fullAddr && (
                <NavLink
                    leftSection={<IconMapPin/>}
                    description={`${loc_city ?? ""}, ${loc_prov ?? ""} ${loc_zip ?? ""}`}
                    label={`${loc_address ?? ""} ${loc_num ?? ""}`}
                />
            )}
            {company.contact_phone && (
                <NavLink
                    component={Link}
                    to={`tel:${company.contact_phone}`}
                    leftSection={<IconPhone/>}
                    description={t("phone")}
                    label={company.contact_phone}
                />
            )}
            {company.contact_whatsapp && (
                <NavLink
                    component={Link}
                    to={`https://wa.me/+${company.contact_whatsapp}`}
                    leftSection={<IconBrandWhatsapp/>}
                    description={t("whatsapp")}
                    label={company.contact_whatsapp}
                />
            )}
            {company.contact_mail && (
                <NavLink
                    component={Link}
                    to={`mailto:${company.contact_mail}`}
                    leftSection={<IconMail/>}
                    description={"Email"}
                    label={company.contact_mail}
                    tt={"none"}
                />
            )}
            <Group m="md">
                <CompanySocials
                    linkedIn={company.contact_linkedin}
                    twitter={company.contact_twitter}
                    mail={company.contact_mail}
                    phone={company.contact_phone}
                    whatsapp={company.contact_whatsapp}
                    website={company.contact_website?.replace("https:/", "/")?.replace("http:/", "/")}
                />
            </Group>
        </Flex>
    );
};

export default CompanyContacts;
