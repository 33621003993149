import { Stack, Box, Button } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LinkProps } from "src/types/LinkProps.type";
import Config from "../classes/application/Config";
import "./CompanyLinksPublic.css";
import iconMaps from "../constants/iconMaps";
import useIsMobile from "../hooks/useIsMobile";
import PublicAccessModal from "../modules/public/components/modals/PublicAccessModal";


const CardButtonLinksPublic = ({ links }: { links: LinkProps[] }) => {
    const { t } = useTranslation();
    const cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl();
    const isMobile = useIsMobile();

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    let marginbottom: string = "10px";

    return (
        <Stack>
            {/* Public Access Modal */}
            <PublicAccessModal isOpen={isModalOpen} onClose={closeModal} />

            {/* Action Buttons */}
            <Box className={"public-buttons"}>
                <Button
                    style={{ marginBottom: marginbottom }}
                    size="lg"
                    fullWidth
                    to="/public/view/job"
                    variant="filled"
                    component={Link}
                    justify="space-between"
                    leftSection={iconMaps.searchJob}
                    rightSection={<IconChevronRight size={14} />}
                >
                    {t("find_jobs")}
                </Button>

                <Button
                    style={{ marginBottom: marginbottom }}
                    size="lg"
                    fullWidth
                    to="/public/view/employee"
                    variant="filled"
                    component={Link}
                    justify="space-between"
                    leftSection={iconMaps.searchEmployee}
                    rightSection={<IconChevronRight size={14} />}
                >
                    {t("find_employees")}
                </Button>

                <Button

                    style={{ marginBottom: marginbottom }}
                    size="lg"
                    fullWidth
                    to="/chatbot"
                    variant="filled"
                    component={Link}
                    justify="space-between"
                    leftSection={iconMaps.chatbot}
                    rightSection={<IconChevronRight size={14} />}
                >
                    {t("chatbot")}
                </Button>

            {/* Other dynamic buttons */}
                {links.map(({ title, Icon = Fragment, to, classes, public_visible }) => (
                    public_visible !== false && (
                        <Button
                            style={{ marginBottom: marginbottom }}
                            key={title + to}
                            size="lg"
                            fullWidth
                            to={to}
                            className={classes}
                            variant="filled"
                            component={Link}
                            justify="space-between"
                            leftSection={<Icon />}
                            rightSection={<IconChevronRight size={14} />}
                            disabled={isPublic}
                            onClick={(e) => {
                                if (isPublic) {
                                    e.preventDefault();
                                    openModal(); // Trigger modal
                                }
                            }}
                        >
                            {t(title)}
                        </Button>
                    )
                ))}
            </Box>

        </Stack>
    );
};

export default CardButtonLinksPublic;
