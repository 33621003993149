import React, { useContext, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
import { Amplify } from "aws-amplify";
import type { WithAuthenticatorProps } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import config from "./amplifyconfiguration.json";
import { NotificationContext } from "./providers/notification.provider";
import useAuth from "./hooks/useAuth";
import Maintenance from "./pages/Maintenance";
import { useGET } from "./hooks/useApi";
import Application from "./classes/Application";
import ReactGA from "react-ga4";
import usePageTracking from "./hooks/usePageTracking";
import TrackingSystem from "./lib/Tracking/TrackingSystem";
import Config from "./classes/application/Config";
import zoneList from "./components/badges/ZoneList";

Amplify.configure(config);



const App = ({ user }: WithAuthenticatorProps) => {
  const { email } = useAuth();
  const notifCtx = useContext(NotificationContext);
  const { data, isLoading, error } = useGET(`health`);
  // Initialize the application instance
  Application.getInstance(import.meta.env);
//  console.log("Running new version with config");

  // Track page views (must be inside the Router context)
   usePageTracking();

  useEffect(() => {
    if (email) {
      console.log("logged in as ", email);
      TrackingSystem.getInstance().sendCustomEvent("logged_in");
    }
  }, [email]);

  useEffect(() => {
    notifCtx?.setReady(!!user);
  }, [user, notifCtx]);

  // Check if maintenance mode or error should be displayed
  if (!Application.getConfig().isUrlConfig()) {
    if (!isLoading && (error || !data)) {
      return <Maintenance />;
    }
  }

  return <RouterProvider router={routes} />;
};

export default App;
