import {Avatar as MatineAvatar} from "@mantine/core";
import {ReactNode} from "react";
import Application from "../classes/Application";

interface Props {
    src?: string | null | undefined;
    size?: number;
    alt?: string;
    children?: ReactNode;
    radius?: "sm" | "md" | "lg" | "xl";
}

const baseUrl = Application.getInstance(import.meta.env).getConfig().getApiUrl();

const Avatar = (props: Props) => {
    const {src, size = 160, alt, children, radius = "xl"} = props;
//    console.log(new Error().stack);

    return (
        <>
            <MatineAvatar
            w={size}
            h={size}
            src={src ? `${baseUrl}/uploads/${src}` : undefined}
            miw={size}
            maw={size}
            alt={alt}
            radius={radius}
        >
            {children}
        </MatineAvatar>
        </>

    );
};

export default Avatar;
