import { signOut } from "aws-amplify/auth";
import TrackingSystem from "../lib/Tracking/TrackingSystem";

const logout = async () => {
  try {
    TrackingSystem.getInstance().sendCustomEvent("sign_out");
    await signOut({ global: true });
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export default logout;
