import {Button, Stack, Box, Text} from "@mantine/core";
import {IconChevronRight} from "@tabler/icons-react";
import {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {LinkProps} from "src/types/LinkProps.type";
import Config from "../classes/application/Config";
import "./CompanyLinks.css";
import iconMaps from "../constants/iconMaps";
import useIsMobile from "../hooks/useIsMobile";
import InfoBoxIcon from "./ui/icon/InfoBoxIcon";

const CardButtonLinks = ({links}: { links: LinkProps[] }) => {
    const {t} = useTranslation();
    const cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl();
    let isMobile = useIsMobile();

    let stl = {}
    if (isPublic) {
        stl = {
            position: "relative",
            top: "-40px"
        };
    }
    let marginbottom: string = "10px";
    return (
        <Stack>

            {/* Display login or register prompt if public page */}
            {isPublic && (
                <>
                    <br/>
                    <br/>
                    <Box
                        className="public-box"
                        style={{

                            position: "relative",
                            top: "-50px",
                            border: "2px solid orange", // Orange border for the public-box
                            borderRadius: "12px",
                            padding: "20px",
                            fontWeight: "bold",
                            backgroundColor: "#efefef",
                            textAlign: "center",
                        }}
                    >
                        <table
                            style={{
                                width: "100%",
                                border: "2px solid orange", // Orange border for the table
                                borderCollapse: "collapse",
                                borderRadius: "12px",
                                backgroundColor: "white"

                            }}
                        >
                            <thead>

                            </thead>
                            <tbody>
                            <tr>
                                <td style={{padding: "10px"}} valign={"top"}>
                                    <InfoBoxIcon/>
                                </td>
                                <td style={{padding: "10px"}}>
                                    <Text
                                        style={{
                                            color: "#6b7280", // Neutral gray for secondary text
                                            fontSize: "1rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {t("Continue_guest_version")}
                                    </Text>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <br/>
                        {/* Highlighted text */}
                        <Text
                            style={{
                                color: "orange", // Orange text for emphasis
                                fontWeight: "bold",
                                fontSize: "1rem",
                            }}
                        >
                            {t("To use all functionalities, you are required to login or register")}
                        </Text>

                        <br/>

                        <Button
                            variant="outline"
                            size="md"
                            style={{
                                backgroundColor: "orange",
                                color: "black",
                            }}
                            onClick={() => {
                                // Open the register/login page using location.href
                                window.location.href = "/app";
                            }}
                        >
                            {t("Register or Login")}
                        </Button>

                        <br/>
                    </Box>


                    <Box
                        style={{
                            position: "relative",
                            top: "-40px"
                        }}
                    >
                        <Button
                            style={{
                                marginBottom: marginbottom
                            }}
                            size="lg"
                            fullWidth
                            to="/public/view/job" // Corrected the target link
                            variant="filled"
                            component={Link}
                            justify="space-between"
                            leftSection={iconMaps.searchJob}
                            rightSection={<IconChevronRight size={14}/>}
                        >
                            {t("find_jobs")}
                        </Button>

                        {/* "Find Employees" button */}
                        <Button
                            style={{
                                marginBottom: marginbottom
                            }}


                            size="lg"
                            fullWidth
                            to="/public/view/employee" // Corrected the target link
                            variant="filled"
                            component={Link}
                            justify="space-between"
                            leftSection={iconMaps.searchEmployee}
                            rightSection={<IconChevronRight size={14}/>}
                        >
                            {t("find_employees")}
                        </Button>

                        {/* "Chatbot" button */}
                        {
                            !isPublic &&
                            (
                                <Button
                                    size="lg"
                                    fullWidth
                                    to="/chatbot" // Corrected the target link
                                    variant="filled"
                                    component={Link}
                                    justify="space-between"
                                    leftSection={iconMaps.chatbot}
                                    rightSection={<IconChevronRight size={14}/>}
                                >
                                    {t("chatbot")}
                                </Button>
                            )
                        }
                    </Box>

                </>
            )}

            {/* Other dynamic buttons */}
            {links.map(({title, Icon = Fragment, to, classes}) => (

                <Button
                    key={title + to}
                    size="lg"
                    fullWidth
                    to={to}
                    className={classes}
                    variant="filled"
                    component={Link}
                    justify="space-between"
                    leftSection={<Icon/>}
                    rightSection={<IconChevronRight size={14}/>}
                    disabled={isPublic} // Disable the button if isPublic is true
                    onClick={(e) => isPublic && e.preventDefault()} // Prevent click if isPublic is true
                >
                    {t(title)}
                </Button>
            ))}
        </Stack>
    );
};

export default CardButtonLinks;
