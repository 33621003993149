import SearchReviewsComponent from "src/components/filters/SearchReviews";
import usePageView from "../../../hooks/usePageView";

const SearchReview = () => {

  usePageView("search_review");
  return <SearchReviewsComponent />;
};

export default SearchReview;
