import { Box, Flex, Group, Skeleton, Stack, Text, Title } from "@mantine/core";
import CountryIcon from "../../../components/badges/CountryIcon";
import Company from "src/types/Company.type";
import { IconBuildingFactory2 } from "@tabler/icons-react";
import Avatar from "../../../components/Avatar";
import CompanyStatus from "../../../components/CompanyStatus";
import Description from "../../../components/Description";
import { useTranslation } from "react-i18next";
import useIsMobile from "src/hooks/useIsMobile";
import { useNavigate } from "react-router-dom";

import "./CompanyBaseComponent.css";

interface Props {
    company?: Partial<Company>;
}

const CompanyBaseComponent = ({ company }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    if (!company) {
        return null;
    }

    return (
        <div className="company-card">
            <Flex
                my="md"
                gap="sm"
                wrap="wrap"
                direction="column"
                justify="center"
                align="center"
                onClick={() => navigate(`/app/view/company/${company.id}`)}
                style={{ cursor: "pointer", textAlign: "center" }}
            >
                <Box mx="auto">
                    <Avatar size={200} src={company.profilePic}>
                        <IconBuildingFactory2 size={90} />
                    </Avatar>
                </Box>

                <Stack maw="95%">
                    {company.name ? (
                        <Title tt="capitalize">{company.name}</Title>
                    ) : (
                        <div style={{ display: "none" }}>
                            <Skeleton w={300} h={26} />
                        </div>
                    )}
                    <CompanyStatus company={company as Company} />
                    <Group justify="center">
                        <CountryIcon id={company.loc_nation ?? ""} />
                        <Text size="small" tt="capitalize">
                            {company.size} {company.type}
                        </Text>
                    </Group>
                    {company.desc ? (
                        <Description text={company.desc} />
                    ) : (
                        <Text c="grey" bg="#eee" p="xs">
                            {t("details_hidden")}
                        </Text>
                    )}
                </Stack>
            </Flex>
        </div>
    );
};

export default CompanyBaseComponent;
