import { useMediaQuery } from "@mantine/hooks";

const useIsMobile = () => {
  //const isSmallScreen = useMediaQuery("(max-width: 1220px)");
    const isSmallScreen = true;
  //

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };

  let is_mobile =  isSmallScreen && isMobileDevice();
  return isMobileDevice();
};

export default useIsMobile;
