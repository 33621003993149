import { Box } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDetectLocale } from "src/hooks/useDetectLocale";
import RegisterCompany from "src/pages/register/RegisterCompany";
import useAuth from "src/hooks/useAuth";
import useApi from "src/hooks/useApi";
import { useNavigate } from "react-router-dom";
import TrackingSystem from "../../../../lib/Tracking/TrackingSystem";
import RegisterCompanyFormComponent from "../RegisterCompanyFormComponent/RegisterCompanyFormComponent";

const RegisterCompanyComponent = () => {
    const navigate = useNavigate();
    const locale = useDetectLocale();
    const [saved, setSaved] = useState(false);
    const { email, name, surname, phone, origin, isLoading } = useAuth();
    const api = useApi();

    // save user data
    useEffect(() => {
        const func = async () => {
            if (!isLoading && email && !saved) {
                const values = {
                    email,
                    name,
                    surname,
                    phone,
                    origin,
                    settings_language: locale,
                };
                const language = localStorage.getItem('i18nextLng');
                await api.PUT(`account/user`, values, undefined, { action: 'register', language: language });
                setSaved(true);
            }
        };
        func();
    }, [isLoading, email, name, surname, phone, origin, locale, api, saved]);

    const onProceed = () => {
        let ts = TrackingSystem.getInstance().sendCustomEvent("user_registered");
        navigate("/app");
    };

    return (
        <Box mx="auto" py="xl">
            <RegisterCompanyFormComponent onSuccess={onProceed} isLoading={isLoading} />
        </Box>
    );
};

export default RegisterCompanyComponent;
