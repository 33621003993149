import { updatePassword, type UpdatePasswordInput } from "aws-amplify/auth";
import TrackingSystem from "../lib/Tracking/TrackingSystem";

export async function handleUpdatePassword({
  oldPassword,
  newPassword,
}: UpdatePasswordInput) {
  try {
    await updatePassword({ oldPassword, newPassword });
    let ts = TrackingSystem.getInstance().sendCustomEvent("password_updated");
  } catch (err) {
    console.log(err);
  }
}
