import { Accordion, Box, Flex, Title } from "@mantine/core";
import _get from "lodash/get";
import _each from "lodash/each";
import _map from "lodash/map";
import _set from "lodash/set";
import { useMemo, useEffect } from "react";
import { InputMatrixOption } from "src/types/InputMatrixOption";
import countActiveValues from "src/utils/countActiveValues";
import EmployeeSkillMatrixComponent from "./EmployeeSkillMatrixComponent";
import EmployeeHandler from "../Handler/EmployeeHandler";

interface Props {
    values: string[];
    setValues: (a: string[]) => void;
    readonly?: boolean;
    availableValueGroups: {
        type: string;
        title: string;
        availableValues: InputMatrixOption[];
    }[];
}

const EmployeeSkillsGroupComponent = ({
                                          values,
                                          setValues,
                                          readonly = false,
                                          availableValueGroups,
                                      }: Props) => {
    // Calculate the count of active values for each group
    const sizes = useMemo(() => {
        const res = {};
        _each(availableValueGroups, (group, index) => {
            const count = countActiveValues(values, group.availableValues);
            _set(res, [index], count);
        });
        return res;
    }, [availableValueGroups, values]);

    // Calculate the total count of active values across all groups
    const totalCount = useMemo(() => {
        return Object.values(sizes).reduce((acc: number, count) => acc + (count as number), 0);
    }, [sizes]);

    const eh = EmployeeHandler.getInstance();

    // Update the total count in EmployeeHandler
    useEffect(() => {
        let ct=eh.getSkillCount();
       // eh.setSkillCount(totalCount);
   //     alert(eh.getSkillCount());

    }, [totalCount]);

    // Return null if all counts are 0
    if (totalCount === 0) {
        return null;
    }

    /*
    eh.setSkillCount(0);


     */
        return (
        <Flex direction="column">
            {
                _map(availableValueGroups, (item, index) => {
                    const count = _get(sizes, [index], 0);
                 //   eh.addSkillCount(count);
                    console.log(count);
                   // console.log("total"+eh.getSkillCount());
                    if (count === 0) {
                        return null;
                    }

                    return (
                    <Flex direction="column" key={item.title}

                          className="employee-skill-group-container"
                    >
                        <Title
                            className="employee-skill-group-title"
                            order={5}
                            style={{
                                textAlign: "center",
                            }}
                        >

                            {item.title} {count ? `[${count}]` : null}

                        </Title>
                        <Box
                            className="employee-skill-group-values">
                            <EmployeeSkillMatrixComponent
                                readonly={readonly}
                                values={values}
                                setValues={setValues}
                                availableValues={item.availableValues}
                            />
                        </Box>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export default EmployeeSkillsGroupComponent;
