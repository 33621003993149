import _startsWith from "lodash/startsWith";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import useAuth from "./useAuth";
import useUser from "./useUser";
import hasRealCompany from "src/utils/hasRealCompany";
import {useI81nTour} from "./useI18nTour";
import ReactGA from "react-ga4";
import Config from "../classes/application/Config";

const useRegisterIfNeeded = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {user, isLoading} = useUser();
    const {email} = useAuth();
    const {setIsOpen} = useI81nTour();
    useEffect(() => {
        if (isLoading) {
            // do not move
            return;
        }

        let cfg = Config.getInstance();
        const onRegisterPath = _startsWith(location.pathname, "/register");
        const onChatbotPath = _startsWith(location.pathname, "/chatbot");
        // Define public paths

        /*
        const onPublicPath =
            location.pathname === "/app/view/employee" ||
            location.pathname === "/app/view/jobs";
    */
        const onPublicPath = cfg.isCurrentPublicUrl();
        /**
         * @ckpublic
         */
            //   alert("ck1");
            // alert(onPublicPath);


        const noCompany = !hasRealCompany(user);

        // Allow /chatbot path without any redirection logic
        if (onChatbotPath) {
            return;
        }

        // Allow public paths without any redirection logic
        if (onPublicPath) {
            return;
        }

        if (noCompany) {
            if (!onRegisterPath) {
                /**
                 * @todo check this
                 */


                setTimeout(() => {

                    let cfg = Config.getInstance();

                    if (!cfg.hasProgressiveRegister()) {

//                        debugger;
  //                      alert("no_progressive registerxx");
                        window.location.href = "/register";
                    }
                }, 1000);

                let cfg = Config.getInstance();

                if (cfg.hasProgressiveRegister()) {
                    return;
                }
                /*
                navigate("/register");
                */
            }
            return;
        }

        const onRootPath = location.pathname === "/";
        const onAppPath = _startsWith(location.pathname, "/app");

        if (!onAppPath || onRootPath) {
            if (onRegisterPath) {
                // registration complete
                ReactGA.event({
                    category: "GzMMCLGd5YoZENGU8-Eq",
                    action: "conversion",
                    value: 1.0,
                });

                setIsOpen(true);
            }
            navigate("/app");
            return;
        }
    }, [isLoading, location.pathname, navigate, user, email, setIsOpen]);
};

export default useRegisterIfNeeded;
