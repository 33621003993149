import { Flex, Paper } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import useIsMobile from "src/hooks/useIsMobile";
import Config from "../../classes/application/Config";
import Application from "../../classes/Application";

interface Props {
  to?: string;
  children?: React.ReactNode;
}

const Card = ({ children, to }: Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const w = isMobile ? "100%" : 430;
  const mx = isMobile ? 0 : "xs";
  const onClick = () => {
    const cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl();

    let app = Application.getInstance();

    //alert(app.isAuth());
    let url=`/app/view/${to}`;
    if(isPublic && ! app.isAuth()) {
      url=`/public/view/${to}`;
    }
    // Ensure the URL is absolute by starting with "/"

    if (to) {
      navigate(url);
    }
  };
  return (
    <Paper
      shadow="md"
      p="md"
      mx={mx}
      my="xs"
      radius="md"
      withBorder
      maw={w}
      miw={w}
      onClick={onClick}
    >
      <Flex direction="column" gap="sm">
        {children}
      </Flex>
    </Paper>
  );
};

export default Card;
