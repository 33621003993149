// @ts-ignore
declare global {
    interface Window {
        fbq: any;
        _fbq: any;
    }
}

import { useEffect } from "react";

// Facebook Pixel Script Component
export const FacebookPixelScript = () => {
    useEffect(() => {
        // Prevent re-initializing the Pixel script if already done
        if (window.fbq) return;

        // Initialize Facebook Pixel
        (function(f: any, b: any, e: string, v: string, n: any, t: any, s: any) {
            n = f.fbq = function() {
                if (n.callMethod) {
                    n.callMethod.apply(n, arguments); // Call method if it exists
                } else {
                    n.queue.push(arguments); // Otherwise, queue the arguments
                }
            };
            if (!f._fbq) f._fbq = n; // Fix: Add _fbq to the window object
            n.push = n;
            n.loaded = true;
            n.version = "2.0";
            n.queue = [];

            // Create and inject the script tag into the page
            t = b.createElement(e);
            t.async = true;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s?.parentNode?.insertBefore(t, s); // Check for s and s.parentNode to avoid possible undefined errors
        })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js", undefined, undefined, undefined);

        // Initialize and track the page view
        window.fbq("init", "802466528505517"); // Your Pixel ID
        window.fbq("track", "PageView"); // Track PageView
    }, []); // Run only once

    return (
        <noscript
            dangerouslySetInnerHTML={{
                __html: `
          <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=802466528505517&ev=PageView&noscript=1"
          alt="Facebook Pixel"
        />
      `,
            }}
        />
    );
};
