import Actions from "src/components/Actions";
import SearchJobs from "src/components/filters/SearchJobs";
import usePageView from "../../../hooks/usePageView";

export default function SearchJob() {

    usePageView("search_job");
    return (
        <>
            <Actions
                title={"find_jobs"}
                infoboxCode={"infobox_project_find"}
            />
            <SearchJobs/>
        </>
    );
}
