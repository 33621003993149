import _isArray from "lodash/isArray";
import _get from "lodash/get";
import showReloadModal from "./showReloadModal";
import {notifyError, notifySuccess} from "./notify.ts";
import FetchFnProps from "src/types/FetchFnProps";
import Application from "../classes/Application";
import Config from "../classes/application/Config";
import AppRouter from "../classes/AppRouter";
import {Timer} from "../lib/Timer";

const getErrorMessage = (err: Error): string =>
    _get(err, "reason") ?? _get(err, "message", err.toString());

function fetchFn(args: FetchFnProps, opts?: { silent: boolean }) {
    let cfg = Application.getInstance().getConfig();
    let {
        url,
        method = "GET",
        body,
        token,
        headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        extraHeaders = {},
    } = args ?? {};


    if (Config.getInstance().isCurrentPublicUrl()) {

        if (!token) {
            token = "guest";
        }
        //alert("ispublic");

    }
    if (!token) {


        notifyError("User not authenticated", 401);


        let app = Application.getInstance();
        //app.runOverlay(3000);
        //    await Timer.sleep(2000);
        // @ts-ignore
        window.needs_error_overlay = 1;
        /*

        /*let e =new Error();
        alert(e.stack);
        */


        // to test
        // http://localhost:5173/public/view/employee/3d775282-e28a-43cd-82b4-b2cce426e8be

        let rtr = AppRouter.getInstance();
        //location.href="/app/";
        //    rtr.redirectToPublic()
        //return null;
        //  return;
        let cfg=Config.getInstance();
        if(!cfg.isCurrentSearchAuthenticatedUrl()) {
            return Promise.reject(new Error("401"));

        }
    //    alert("no redir");

    }

    if (!url) {
        notifyError("Invalid request", 400);
        return Promise.reject(new Error("400"));
    }

    const mergedHeaders = {
        ...headers,        // default headers
        ...extraHeaders,  // custom headers (override or add)
        Authorization: `Bearer ${token}`,
    };

    const options = {
        method,
        // credentials: "include",
        headers: mergedHeaders,
        body: JSON.stringify(body),
    };

    return fetch(url, options).then(async (response) => {

        if (response.ok) {
            if (!opts?.silent) {
                notifySuccess(method);
            }
            if (_get(headers, "Accept") === "application/json") {
                return response.json();
            }
            return response;
        }

        switch (response.status) {
            case 497:
            case 498: {
                if (cfg.isUrlConfig()) {
                    return;
                }
                // showReloadModal("expired_token", "expired_token_desc");
                window.location.reload();
                break;
            }
            // case 498: {
            //   showReloadModal("invalid_token", "invalid_token_desc");
            //   break;
            // }
            case 401:
            case 499: {
                showReloadModal("missing_token", "missing_token_desc");
                break;
            }

            case 403: {
                notifyError("unauth", response.status);
                break;
            }

            // case 500: {
            //   notifyError("server error", response.status);
            //   break;
            // }

            default: {
                const errorJson = (await response.json()) || null;
                if (errorJson) {
                    console.log(errorJson);
                    if (_isArray(errorJson)) {
                        errorJson.map((e) => notifyError(getErrorMessage(e), 400));
                    } else {
                        notifyError(getErrorMessage(errorJson), 400);
                    }
                }
                const errorMessage = response.toString() || "error";
                notifyError(errorMessage, response.status);
            }
        }

        return Promise.reject(new Error(`${response.status}`));
    });
}

export default fetchFn;
