import { Text, Button, Flex } from "@mantine/core";
import { IconUser } from '@tabler/icons-react'; // Ensure you're using the correct import path
import Avatar from "./Avatar";
import Admin from "src/types/Admin.type";
import Config from "../classes/application/Config";

interface Props {
    user: Admin;
}

function UserButton({ user }: Props) {
    const cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl();

    const handleNavigation = () => {
        const url = isPublic ? "/app/view" : `/app/view/user/${user?.id}`;
        window.location.href = url; // Perform the redirect
    };

    return (
        <Button
            fullWidth
            leftSection={
                (false && isPublic) ? (
                    <IconUser size={48} /> // Using a default user icon
                ) : (
                    <Avatar src={user?.profilePic ?? ""} size={48} />
                )
            }
            justify="left"
            my="md"
            ml="-10"
            h={50}
            onClick={handleNavigation}  // Trigger navigation on button click
        >
            <Flex direction="column">
                <Text size="sm" fw={500} ta="left">
                    {isPublic ? "Guest" : user?.name}
                </Text>
                {!isPublic && (
                    <Text size="xs" tt={"initial"} c="black" ta="left">
                        {user?.email}
                    </Text>
                )}
            </Flex>
        </Button>
    );
}

export default UserButton;
