import {Flex} from "@mantine/core";
import LanguageIcon from "./LanguageIcon";

interface Props {
    languages: string[] | undefined;
    mt?: string; // Optional margin-top parameter, defaulted to "xs"
}

const LanguageList = ({languages = [], mt = "xs"}: Props) => {
    return (
        <Flex mt={mt} wrap="wrap" gap="xs">
            {languages.map((c) => (
                <LanguageIcon id={c} key={c}/>
            ))}
        </Flex>
    );
};

export default LanguageList;
