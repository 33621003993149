import {useTranslation} from "react-i18next";
import {Button, Tooltip} from "@mantine/core";
import {Link} from "react-router-dom";
import SpendCreditsButton from "./SpendCreditsButton";

interface Props {
    to?: string;
    label?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    variant?: string;
    cost?: number;
    color?: string;
    disabled?: boolean;
    Icon?: React.FC;
    tooltip?: string;
    isReady: boolean;
    hasConfirm?: boolean; // Optional
    confirmTitle?: string; // Optional
    confirmMessage?: string; // Optional
}

const ActionButton = (props: Props) => {
    const {
        to = "/",
        label = "",
        variant = "primary",
        onClick,
        cost,
        color,
        disabled = false,
        Icon = undefined,
        tooltip,
        isReady = false,
    } = props;
    const {t} = useTranslation();

    // alert("ActionButton"+JSON.stringify(props));

    if (onClick && cost) {
        return (
            <SpendCreditsButton
                label={label}
                cost={cost}
                desc={tooltip ?? ""}
                onSuccess={onClick}
                key={label}
                disabled={!isReady}
            />
        );
    }

    const button = onClick ? (
        <Button
            disabled={disabled}
            onClick={onClick}
            variant={variant}
            key={label}
            c={color}
            rightSection={Icon && <Icon/>}
        >
            {t(label)}
        </Button>
    ) : (
        <Button
            /*
            style={{
                height: "50px",
                padding: "12px 20px", // Larger padding
                borderRadius: "12px", // Rounded corners
            }}
            */
            disabled={disabled}
            data-disabled={disabled}
            component={Link}
            to={disabled ? "" : to}
            variant={variant}
            key={t(label)}
            c={color}
            rightSection={Icon && <Icon/>}
        >
            {t(label)}
        </Button>
    );

    return (
        <Tooltip
            label={tooltip}
            opened={!!tooltip}
            position="top"
            withArrow
            offset={5}
        >
            {button}
        </Tooltip>
    );
};

export default ActionButton;
