import { useEffect } from "react";
import ReactGA from "react-ga4";
import { createBrowserHistory } from "history";  // Ensure you import from the correct package

const history = createBrowserHistory();  // Create a history object

const usePageTracking = () => {
    useEffect(() => {

        // Listen for route changes
/*
        const unlisten = history.listen((location) => {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
            console.log("GA4 Tracking:", location.pathname); // Optional: For debugging
        });

        // Cleanup listener on component unmount
        return () => unlisten();
  */
    }, []);
};

export default usePageTracking;
