import _noop from "lodash/noop";
import _map from "lodash/map";
import _groupBy from "lodash/groupBy";
import {useGET} from "src/hooks/useApi";
import MultiInputMatrix from "./MultiInputMatrix";
import {InputMatrixOption} from "src/types/InputMatrixOption";
import {MultiSelect, Stack, Text, Title} from "@mantine/core";
import {Fragment, ReactNode, useMemo} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    values: string[];
    setValues?: (a: string[]) => void;
    errors?: ReactNode | null;
    asSelect?: boolean;
}

interface Skill {
    type: string;
    title: string;
    availableValues: InputMatrixOption[];
}

const SkillSelectNew = (props: Props) => {
    const {setValues, values, errors, asSelect = false} = props;
    const {t} = useTranslation();
    let {data} =useGET<Skill[]>(`static/skills`);



    if(true&&data) {
     //   alert(JSON.stringify(data));

        data.forEach(entry => {
            let ent= {

            }
            if (entry.title.toLowerCase().includes("online robot programming")) {

               // alert(entry.title);
             //   alert(entry.availableValues);
                for (let i in entry.availableValues) {

                    let ent=entry.availableValues[i];
                    //alert(ent.value);
                    if(ent.label==="Comau") {
                   //     ent.label="sticazzi";
                        ///       alert("gogogo");
                    }
                    //alert(label);
                }
                /*
                entry.availableValues.forEach(ent)
                {
                    if(ent.label==="Comau") {
                        alert("ddood");
                    }
                }
                */

            }

      //      console.log(entry.title.toLowerCase());
            if (false) {

                /*
            entry.availableValues.forEach(ent)
            {
             if(ent.label==="Comau") {
                 alert("ddood");
             }
            }
                 */
            }
        });
    }

  //  return <></>
    if(!data) {

    }


    const groups = useMemo(() => _groupBy(data, "type"), [data]);

    if (asSelect) {
        const skills = _map(data, (row) => ({
            group: row.title,
            items: row.availableValues,
        }));

        console.log(skills);

        /*
        const skills = _map(data, (row) => ({
            group: row.title,
            items: [
                { label: "Fake Skill 1", value: "fake1" }, // Fake value 1
                { label: "Fake Skill 2", value: "fake2" },
            ],
        }));
        */


        /*
        const skills = _map(data, (row) => ({
            group: row.title,
            items: [
                { label: "Fake Skill 1", value: "fake1" }, // Fake value 1
            ],
        }));
       */
        const onChange = (v: string[]) => {
            console.log("[SKILL]setting values");
            setValues && setValues(v);
        };

        return (
            <MultiSelect
                searchable
                limit={1000}
                label="skills"
                value={values}
                onChange={onChange}
                data={skills}
                error={errors}
            />
        );
    }

    return (
        <>
            {errors && (
                <Text size="xs" c="red">
                    {errors}
                </Text>
            )}
            <Stack style={{border: errors ? "1px solid red" : "none"}}>
                {_map(groups, (g, id) => (
                    <Fragment key={id}>
                        <Title order={4}>{t(id)}</Title>
                        <MultiInputMatrix
                            values={values}
                            readonly={!setValues}
                            setValues={setValues ?? _noop}
                            availableValueGroups={g}
                        />
                    </Fragment>
                ))}
            </Stack>
        </>
    );
};

export default SkillSelectNew;
