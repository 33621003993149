import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
//import JobOfferView from "src/components/view/Job.view";
import { useGET } from "src/hooks/useApi";
import JobOffer from "src/types/JobOffer.type";
import { Url } from "../../../lib/http/Url";
import Config from "../../../classes/application/Config";
import { usePublicGET } from "../../../hooks/useApiPublic";
import { PaginationResponse } from "../../../types/Pagination.Type";
import Employee from "../../../types/Employee.type";
import EmployeeDetailView from "../../../entity/Employee/view/detail/EmployeeDetailView";
import EmployeeView from "../../../components/view/Employee.view";
import JobOfferView from "../../../components/view/Job.view";
import JobOfferDetailView from "../../../entity/JobOffer/view/detail/JobOfferDetailView";
import usePageView from "../../../hooks/usePageView";
import AppRouter from "../../../classes/AppRouter";
import Application from "../../../classes/Application";
export default function ViewJobPage() {


  const params = useParams();

  usePageView("job");
  const url = `res/job/${params.id}`;

  let data: any = undefined;
  let isLoading = true;
  let error: any = null;

  let burl = Url.fromBrowser();
  let mode = "new";

  if (burl.queryParamIs("mode", "new")) {
    mode = "new";
  }

  let cfg = Config.getInstance();
  if (cfg.hasPublicAreaEnabled()) {
    mode = "new";
  }

  if (cfg.isCurrentPublicUrl()) {
    ({ data, isLoading, error } = usePublicGET<PaginationResponse<JobOffer>>(url, {
      isPublic: true,
    }));
  } else {
    ({ data, isLoading, error } = useGET<PaginationResponse<JobOffer>>(url, {
      isPublic: true,
    }));
  }
  mode="new";

  let rtr = AppRouter.getInstance();
  if (location.href.includes("from_public") && rtr.needRedirect(location.href)) {
    if (error !== undefined) {
      isLoading = true;
      error = undefined;
    }
  }
  let isAuth = Application.getInstance().isAuth();
  let isPublic = cfg.isCurrentPublicUrl();

  // @ts-ignore
  if (isPublic && window.app_is_auth) {

    return <></>
  }
  if (burl.queryParamIs("mode", "old")) {
    mode = "old";
  }
  return (
      <>
        <Loader isLoading={isLoading} error={error} hasData={!!data}>
          {mode === "new" ? (
              <JobOfferDetailView employee={data} />
          ) : (

              <JobOfferView jobOffer={data} />
          )}
        </Loader>
      </>
  );

  /*
  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      {mode === "new" ? (
        <JobOfferDetailView jobOffer={data} />
      ) : (
      )}
    </Loader>
  );
  */
}
