import { useTranslation } from "react-i18next";
import { Box, Stack, Title } from "@mantine/core";
import Loader from "src/components/Loader";
import Video from "src/components/Video";
import { useGET } from "src/hooks/useApi";
import Description from "src/components/Description";
import { Tutorial as TutorialType } from "src/types/Tutorial.type";
import { useParams } from "react-router-dom";
import usePageView from "../hooks/usePageView";

export default function Tutorial() {
  const { tutorialId } = useParams();
  const { t } = useTranslation();
  const { data, isLoading, error } = useGET<TutorialType>(
    `config/tutorial/${tutorialId}`
  );

  usePageView("tutorial");

  return (
    <Stack align="center">
      <Title>{t("tutorial")}</Title>
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        <Stack mx="lg">
          <Title order={2}>{data?.title}</Title>
          <Description text={data?.desc} />
          <Box mx="auto">
            <Video url={data?.url} />
          </Box>
        </Stack>
      </Loader>
    </Stack>
  );
}
