import {Box, Button, Group, Stack} from "@mantine/core";
import {useState} from "react";
import {useTranslation} from "react-i18next";

import RegisterCompanyCreate from "../../../../pages/register/RegisterCompanyCreate";
import usePageView from "../../../../hooks/usePageView";
import RegisterCompanyJoin from "../../../../pages/register/RegisterCompanyJoin";
import RegisterCompanyCreateComponent from "./RegisterCompanyCreate/RegisterCompanyCreateComponent";

interface Props {
    onSuccess: () => void;
    isLoading: boolean;
}

// user decides here his role
// - create new company -> owner
// - join existing company -> readonly

export default function RegisterCompanyFormComponent({onSuccess, isLoading}: Props) {
    const [choice, setChoice] = useState(true);
    const {t} = useTranslation();
    usePageView("company_create");

    return (
        <Stack>
            <Group justify="center">
                <div style={{
                    display: "none"
                }}>
                    <Button.Group>
                        <Button
                            variant={choice ? "primary" : "default"}
                            onClick={() => setChoice(true)}
                        >
                            {t("create_company")}
                        </Button>
                        <Button
                            variant={choice ? "default" : "primary"}
                            onClick={() => setChoice(false)}
                        >
                            {t("join_company")}
                        </Button>
                    </Button.Group>
                </div>

            </Group>
            <Box px="md">
                {choice ? (
                    <RegisterCompanyCreateComponent nextTab={onSuccess} isLoading={isLoading}/>
                ) : (
                    <RegisterCompanyJoin nextTab={onSuccess}/>
                )}
            </Box>
        </Stack>
    );
}
