import {Box, Divider, Flex, Paper, ScrollArea} from "@mantine/core";
import UserButton from "src/components/UserButton";
import NavbarLinks from "src/components/NavbarLinks";
import {altLinks, mainLinks} from "src/constants/sidebar.const";
import useUser from "src/hooks/useUser";
import useIsMobile from "src/hooks/useIsMobile";
import {useLinksWithTutorials} from "src/hooks/useLinksWithTutorials";
import Config from "../classes/application/Config";

function Sidebar() {
    const {user} = useUser();
    const isMobile = useIsMobile();
    const footer = <UserButton user={user}/>;
    const linksWithTutorials = useLinksWithTutorials(altLinks);
    const cfg = Config.getInstance();
    let force_show_navbar_links: boolean=false;

    if (cfg.hasProgressiveRegister()) {
        force_show_navbar_links= true;
    }

    return (
        <Paper
            my="xs"
            ml={isMobile ? -5 : "xs"}
            mr={isMobile ? 7 : 0}
            bg="primary"
            py="md"
            pr={0}
            pl={0}
            h="100%"
            style={{
                borderRadius: "0 0 20px 20px",
            }}
        >
            <ScrollArea h={"100%"}>
                <Flex justify="space-between" direction="column" h={"100%"}>
                    {(user?.companyId ||force_show_navbar_links) && <NavbarLinks links={mainLinks}/>}

                    <div style={{
                        display: "none"
                    }}>
                        <NavbarLinks links={mainLinks}/>
                    </div>
                    <Box>

                        <NavbarLinks links={linksWithTutorials}/>
                        <Divider my="lg" mx="lg"/>
                        <Box mr="md">{footer}</Box>
                    </Box>
                </Flex>
            </ScrollArea>
        </Paper>
    );
}

export default Sidebar;
