import Admin from "src/types/Admin.type";
import SimpleTable from "./SimpleTable";
import AdminTableRow from "./rows/AdminTable.Row";
import usePageView from "../../hooks/usePageView";

interface Props {
  data: Admin[];
  readOnly: boolean;
}

const cols = ["", "name", "role", "email_address", ""];

export function AdminTable({ data, readOnly }: Props) {
  usePageView("admin_list");


  return (
    <SimpleTable
      cols={cols}
      rows={data}
      section="user"
      Row={AdminTableRow}
      readOnly={readOnly}
    />
  );
}
