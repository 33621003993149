import Config from "./application/Config";
import Application from "../classes/Application";
import searchReview from "../pages/data/list/SearchReview";
import { NavigateFunction } from 'react-router-dom';

class AppRouter {
    private static _instance: AppRouter;

    private constructor() {

        this._cancel_to_url = "";
        this._next_to_url = "";
    }

    protected _cancel_to_url: string;
    protected _next_to_url: string;

    public static getInstance(): AppRouter {
        if (!AppRouter._instance) {
            AppRouter._instance = new AppRouter();
        }
        return AppRouter._instance;
    }

    // Getter and Setter for _cancel_to_url
    public getCancelToUrl(): string {

  //      alert(localStorage.getItem("rtr_canc_to_url"));
        //         if (!this._cancel_to_url) {
        //             this._cancel_to_url = localStorage.getItem("rtr_canc_to_url") || "";
        //         }
        return this._cancel_to_url;
    }

    public setCancelToUrl(url?: string): void {
        if (!url) {
            url = location.href;
        }
        this._cancel_to_url = url;
        localStorage.setItem("rtr_canc_to_url", url);
      //  alert("url is set to "+url);
    }

    // Getter and Setter for _next_to_url
    public getNextToUrl(): string {
        if (!this._next_to_url) {
            this._next_to_url = localStorage.getItem("rtr_next_to_url") || "";
        }
        return this._next_to_url;
    }

    public setNextToUrl(url?: string): void {
        if (!url) {
            url = location.href;
        }
        this._next_to_url = url;

        localStorage.setItem("rtr_next_to_url", url);
    }

    public navigateTo(path: string): void {
        window.removeEventListener("beforeunload", function () {});

        if (location.href.includes("company/register")) {
            window.onbeforeunload = null;

            if (path.startsWith("http")) {
                const url = new URL(path); // Creates a URL object from the string
                path = url.pathname + url.search + url.hash; // Extracts only the pathname, search, and hash

//                path = path.split("//")[1] ?? path; // Rimuove il protocollo se presente
            }
          //  alert(path);
            window.history.pushState({}, "", path); //
          //  alert("yyy");// pdate URL without reloadi
            window.dispatchEvent(new PopStateEvent('popstate'));

//            navigate(path);

     //       window.open(path,"_self");
            return;
        }

        window.onbeforeunload = null;

        if (path.startsWith("http")) {
            window.location.href = path;
            return;
        }

        if (this.needRedirect(path)) {
            window.location.href = this.fixRoutePrefix(path);
        } else {
            window.location.href = path;
        }
    }


    public fixRoutePrefix(path: string, to: string = "detect", from: string = "detect"): string {
        const isAuth = Application.getInstance().isAuth();
        let prefix = isAuth ? "/app" : "/public";

        if (to !== "detect") {
            prefix = `/${to}`;
        }

        const url = new URL(path, window.location.origin);
        const cleanPath = url.pathname.replace(/^\/(app|public)/, "");

        return `${prefix}${cleanPath}${url.search}${url.hash}`;
    }

    public openAppUrl(url: string, target: string | null = null): void {
        const fixedUrl = this.needRedirect(url) ? this.fixRoutePrefix(url) : url;
        if (target) {
            window.open(fixedUrl, target);
        } else {
            window.location.href = fixedUrl;
        }
    }

    public needRedirect(path: string, expectedTo: string | null = null): boolean {
        if (path.includes("company/register")) {
            return false;
        }

        const isAuth = Application.getInstance().isAuth();
        let expectedPrefix = isAuth ? "/app" : "/public";

        if (expectedTo !== null) {
            expectedPrefix = `/${expectedTo}`;
        }

        return !path.startsWith(expectedPrefix);
    }

    public redirectToAppIfNeeded(path: string): void {
        const isAuth = Application.getInstance().isAuth();
        if (isAuth && this.needRedirect(path, "app")) {
            window.location.href = this.fixRoutePrefix(path);
        }
    }

    public redirectToApp(path: string, qs: string | null = null): void {
        if (qs !== null) {
            path = path + "?" + qs;
        }
        window.location.href = this.fixRoutePrefix(path, "app");
    }

    public redirectToPublic(path: string | null = null): void {
        if (path === null) {
            path = location.href;
        }

        let dest = this.fixRoutePrefix(path, "public");

        location.href = dest;
    }
}

export default AppRouter;
