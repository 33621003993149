import React from "react";
import {Button, Flex, useMantineTheme} from "@mantine/core";
import {useTranslation} from "react-i18next";
import {confirmAlert} from "react-confirm-alert";
import Actions from "src/components/Actions";
import useIsOwn from "src/hooks/useIsOwn";
import Config from "../../../../classes/application/Config";
import LayoutHandler from "../../../../classes/ui/LayoutHandler";

interface EmployeeDetailActionsProps {
    isPublic: boolean;
    actionSubmit: {
        label: string;
        to: string;
        disabled?: boolean;
        hasConfirm?: boolean;
        confirmTitle?: string;
        confirmMessage?: string;
        confirmButtonConfirm?: string;
        mode_to?: string;
    };
    isOwn: boolean;
    id: string;
}

const EmployeeDetailActions: React.FC<EmployeeDetailActionsProps> = ({
                                                                         isPublic,
                                                                         actionSubmit,
                                                                         isOwn,
                                                                         id,
                                                                     }) => {
    const {t} = useTranslation();
    const theme = useMantineTheme();
    const lh = LayoutHandler.getInstance();
    let isMobile = lh.isMobile();

    // enable for floating
    isMobile = lh.isMobileDevice();

    const handleButtonClick = () => {
        if (actionSubmit.hasConfirm) {
            confirmAlert({
                title: actionSubmit.confirmTitle || "",
                message: actionSubmit.confirmMessage || "",
                buttons: [
                    {
                        label: t(actionSubmit.confirmButtonConfirm || "Confirm"),
                        onClick: () => {
                            window.location.href = actionSubmit.to;
                        },
                        style: {
                            backgroundColor: theme.colors.primary[6],
                            color: "white",
                            borderRadius: "4px",
                            margin: "10px",
                        },
                    },
                    {
                        label: t("cancel").charAt(0).toUpperCase() + t("cancel").slice(1),
                        onClick: () => {
                        },
                        style: {
                            backgroundColor: theme.colors.gray[4],
                            color: "white",
                            margin: "10px",
                            borderRadius: "4px",
                        },
                    },
                ],
            });
        } else {
            window.location.href = actionSubmit.to;
        }
    };

    return (
        <div
            style={
                isMobile
                    ? {
                        position: "fixed",
                        bottom: 0,
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "100%",
                        backgroundColor: "white",
                        padding: "10px",
                        boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        justifyContent: "center",
                    }
                    : {}
            }
        >

            {isMobile? ( <div>
                <br/>
                <br/>
                <br/>
            </div>):<></>}
            <Flex justify="center" align="center" style={{height: "6vh"}}>
                {isPublic ? (
                    <Button variant="filled" onClick={handleButtonClick} disabled={actionSubmit.disabled}>
                        {t(actionSubmit.label)}
                    </Button>
                ) : (
                    <Actions
                        actions={[
                            isOwn
                                ? {
                                    label: t("edit_employee"),
                                    to: `/app/edit/employee/${id}`,
                                    variant: "default",
                                }
                                : actionSubmit,
                        ]}
                    />
                )}
            </Flex>
        </div>
    );
};

export default EmployeeDetailActions;