import CompanyView from "src/components/view/Company.view";
import useUser from "src/hooks/useUser";
import usePageView from "../hooks/usePageView";

export default function Dashboard() {
  const { user } = useUser();
  usePageView("Dashboard");

  return <CompanyView company={user?.Company} />;
}
