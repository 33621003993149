export class MenuBarHandler {
    private static instance: MenuBarHandler;

    protected _isOpen: boolean = false;
    protected _shouldDisplay: boolean = true;
    protected _position: { left: number; right: number; top: number } = { left: 0, right: 0, top: 0 };

    private constructor() {}

    public static getInstance(): MenuBarHandler {
        if (!MenuBarHandler.instance) {
            MenuBarHandler.instance = new MenuBarHandler();
        }
        return MenuBarHandler.instance;
    }

    public isOpened(): boolean {
        return this._isOpen;
    }

    public toggleOpen(): void {
        this._isOpen = !this._isOpen;
    }

    public shouldBeDisplayed(): boolean {
        return this._shouldDisplay;
    }

    public toggleDisplay(): void {
        this._shouldDisplay = !this._shouldDisplay;
    }

    public getPosition(): { left: number; right: number; top: number } {
        return this._position;
    }

    public setPosition(left: number = 0, right: number = 0, top: number = 0): void {
        this._position = { left, right, top };
    }
}
