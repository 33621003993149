import { Flex, Image, Loader, Stack, Text, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Application from "../classes/Application";
import {Timer} from "../lib/Timer";

export default function Error({
  title = "error_title",
  desc = "",
  isLoading = false,
  showBackToHome = false,
}: {
  title?: string;
  desc?: string;
  isLoading?: boolean;
  showBackToHome?: boolean;
}) {
  const { t } = useTranslation();
  const icon = (
    <Image
      src={`/${title}.webp`}
      placeholder="/error_title.webp"
      w={120}
      mx="auto"
    />
  );

  let need_overlay=0;

  // @todo this has to be rewritten
  // @ts-ignore
  if(window.needs_error_overlay==1) {
    // @ts-ignore
    window.needs_error_overlay=0;
    let app = Application.getInstance();
    //app.runOverlay(5000);
    return (
        <Stack p="md" mx="auto" my="xl" ta="center">
        </Stack>
    );
  }

  let isAuth = Application.getInstance().isAuth();


  // @todo change up to there

  return (
      <Stack p="md" mx="auto" my="xl" ta="center">
        {isLoading ? <Loader color="blue" m="auto" /> : icon}
        <Title>{t(title)}</Title>
        <Text>{t(desc)}</Text>
        {showBackToHome && (
            <Flex direction="row" mx="auto">
              <Text>
                <Link to="/">{t("error_link_label")}</Link>
              </Text>
              <IconArrowRight />{" "}
            </Flex>
        )}
      </Stack>
  );
}
