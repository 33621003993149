import Card from "src/components/cards/Card";
import { Divider } from "@mantine/core";
import CompanySection from "src/components/Sections/Company.Section";
import EmployeeSection from "src/components/Sections/Employee.Section";
import EmployeeInfoSection from "src/components/Sections/EmployeeInfo.Section";
import Employee from "src/types/Employee.type";
import Company from "src/types/Company.type";
import Config from "../../classes/application/Config";

interface Props {
  employee?: Employee;
  company?: Partial<Company>;
}

const EmployeeCard = ({ company, employee }: Props) => {
  if (!employee) {
    return null;
  }

  const cfg = Config.getInstance();
  const isPublic = cfg.isCurrentPublicUrl();

  // Ensure the URL is absolute by starting with "/"
  const employeeUrl = isPublic
      ? `employee/${employee.id}`
      : `employee/${employee.id}`;

  return (
      <Card to={employeeUrl}>
        <EmployeeSection employee={employee} />
        <EmployeeInfoSection employee={employee} />
        {company && (
            <>
              <Divider my="md" />
              <CompanySection company={company} />
            </>
        )}
      </Card>
  );
};

export default EmployeeCard;
