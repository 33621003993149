import { Button, ButtonGroup } from "@mantine/core";
import { IconLogin, IconUserPlus } from "@tabler/icons-react";
import Application from "../classes/Application";
import { useTranslation } from "react-i18next";

function LoginRegister() {
    let hide: boolean = false; // Set this condition based on whether you want to hide the button group or not
    const { t } = useTranslation();

    let app = Application.getInstance();
    if (app.isAuth()) {
        hide = true;
    }

    return (
        <>
            {
                !hide && (
                    <ButtonGroup>
                        {/* Login Button */}
                        <Button variant="default" onClick={() => location.href="/app"}>
                            <IconLogin />
                            {t("login_register")}
                        </Button>

                    </ButtonGroup>
                )
            }
        </>
    );
}

/*
{
 <Button variant="default" onClick={() => alert("Register clicked")}>
    <IconUserPlus />
    {t("register")}
</Button>
Register Button }
*/
export default LoginRegister;
