import { useNavigate, useParams } from "react-router-dom";
import ApplicationForm from "src/components/forms/Application.form";
import Loader from "src/components/Loader";
import useApi, { useGET } from "src/hooks/useApi";
import Application from "src/types/Application.type";
import usePageView from "../../../hooks/usePageView";

export default function EditApplication() {
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi();

  const url = `res/application/${id}`;
  const { data, isLoading, error } = useGET<Application>(url);

  usePageView("form_application_edit");
  const onSubmit = (values: Partial<Application>) => {
    api.PUT(`res/application/${id}`, values);
    navigate(`/app/view/application/${id}`);
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <ApplicationForm
        title="update_application"
        initialValues={data}
        onSubmit={onSubmit}
      />
    </Loader>
  );
}
