import Actions from "src/components/Actions";
import SearchEmployees from "src/components/filters/SearchEmployees";
import usePageView from "../../../hooks/usePageView";

export default function SearchEmployee() {


    usePageView("search_employee");
  //  alert(11);
    return (
        <>
            <Actions
                title={"find_employees"}
                infoboxCode={"infobox_supplier_find"}
            />
            <SearchEmployees/>
        </>
    );
}
