import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import {
    AppShell,
    Box,
    LoadingOverlay,
    useMantineColorScheme,
    useMantineTheme,
} from "@mantine/core";
import {useDisclosure, useViewportSize} from "@mantine/hooks";
import Sidebar from "src/components/Sidebar";
import {Outlet} from "react-router-dom";
import Header from "./Header";
import {layoutConst} from "src/constants/layout.const";
import usePing from "src/hooks/usePing";
import useIsMobile from "src/hooks/useIsMobile";
import useAuth from "src/hooks/useAuth";
import useScrollTop from "src/hooks/useScrollTop";
import SupportLinks from "./SupportLinks";
import TrackingSystem from "../lib/Tracking/TrackingSystem";
import GA4Tracker from "../components/GA4Tracker";
import FadeOverlayComponent from "../components/ui/Page/FadeOverlayComponent";
import React, { useState } from "react";
import fetchFn from "../helpers/fetcher";

function MainLayout() {
    usePing();
    useScrollTop();
    const {width} = useViewportSize();
    const [mobileOpened, {toggle: toggleMobile}] = useDisclosure();
    const [desktopOpened, {toggle: toggleDesktop}] = useDisclosure();
    const xlScreenSize = width > layoutConst.XL_SCREEN_SIZE;
    const {email} = useAuth();
    const theme = useMantineTheme();
    const {colorScheme, setColorScheme} = useMantineColorScheme();
    setColorScheme("light");
    const bgColor =
        colorScheme === "light" ? theme.colors.gray[2] : theme.colors.gray[8];
    let isMobile = useIsMobile();
    if (isMobile == undefined) {
        isMobile = false;
    }
    const [fadeTrigger, setFadeTrigger] = useState(false);

    const handleFetch = async () => {
        setFadeTrigger(true); // Trigger fade effect from React
        try {
            await fetchFn({ url: "/api/data" , method: "GET"}); // Your fetch function
        } catch (error) {
            console.error(error);
        }
    };

    //let showBurger:boolean=isMobile && xlScreenSize;

    let showBurger: boolean = isMobile;


    const firstVisitKey = "first_visit";

    if (!localStorage.getItem(firstVisitKey)) {
        localStorage.setItem(firstVisitKey, "true");
        let ts = TrackingSystem.getInstance().sendCustomEvent("first_visit");
    } else {
        // Not the first visit actions
    }


    const headerHeight = layoutConst.HEADER_HEIGHT;
    const toggleOnMobile = () => {
        if (isMobile) {
            toggleMobile();
        }
    };
    const mainHeight = `calc(100vh - ${headerHeight}px)`;
    const mainWidth = xlScreenSize
        ? `calc(100vw - ${layoutConst.SIDEBAR_WIDTH})`
        : "100%";


    function shouldCollapseNavbar(): boolean {
        if (isMobile && !mobileOpened) {
            return true;
        }
        return false;
    }

    // TODO HACK
    // in order to navigate on notification click we need to make notifications inside
    // the router provider, a cleaner solution would be to rewrite the routes as a component
    return (
        <>
            <GA4Tracker trackingId=""/>
            <FadeOverlayComponent trigger={fadeTrigger} duration={4000} /> {/* React controlled fade */}


            <AppShell
                navbar={{
                    width: isMobile ? "" : layoutConst.SIDEBAR_WIDTH,
                    breakpoint: isMobile ? layoutConst.SIDEBAR_BREAKPOINT : 300,
                    collapsed: {
                        mobile: shouldCollapseNavbar(),
                        desktop: false
                        //   desktop: !desktopOpened && !xlScreenSize,
                    },
                }}
                header={{
                    height: headerHeight,
                }}
                py="md"
                bg={bgColor}
            >
                <AppShell.Header pr={isMobile ? 0 : "md"} withBorder={false} bg={bgColor}>
                    <Header
                        showBurger={showBurger}
                        toggleDesktop={toggleDesktop}
                        toggleMobile={toggleMobile}
                    />
                </AppShell.Header>

                <AppShell.Navbar
                    onClick={toggleOnMobile}
                    withBorder={false}
                    bg="transparent"
                >
                    <Sidebar/>
                </AppShell.Navbar>

                <AppShell.Main h={mainHeight} w={mainWidth}>
                    <Box bg={bgColor} mt="md">
                        <LoadingOverlay
                            visible={!email}
                            zIndex={1000}
                            overlayProps={{radius: "sm", blur: 2}}
                        />
                        <Box mt={isMobile ? 80 : 0} mx={isMobile ? "xs" : "md"}>
                            <Outlet/>
                        </Box>
                    </Box>
                    {/* support button */}
                    <SupportLinks small/>
                </AppShell.Main>
            </AppShell>
        </>
    );
}

export default MainLayout;
