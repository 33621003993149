import _omit from "lodash/omit.js";
import { Box, Stack, TextInput, Title } from "@mantine/core";
import FormSection from "src/components/forms/FormSection";
import Company from "src/types/Company.type";
import { useForm } from "@mantine/form";
import { CompanyValidate } from "src/pages/data/validates/companyXS.validate";
import { useState } from "react";

import useFormActions from "src/hooks/useFormActions";
import handleFileUpload from "src/utils/handleFileUpload";
import COMPANY_PROTECTED_FIELDS from "src/constants/companyProtectedFields";
import useUnloadAlert from "src/hooks/useUnloadAlert";
import { useTranslation } from "react-i18next";
import DescriptionInput from "src/components/DescriptionInput";

import ImagePreview from "../../../../../components/ImagePreview";
import InputUnique from "../../../../../components/InputUnique";
import InputFile from "../../../../../components/InputFile";
import Actions from "../../../../../components/Actions";
import RegisterCompanyActions from "./RegisterCompanyActions/RegisterCompanyActions";
import AppRouter from "../../../../../classes/AppRouter";

interface Props {
    title?: string;
    initialValues?: Partial<Company>;
    onSubmit?: (values: Partial<Company>, file?: File) => void;
}

export default function sRegisterCompanyForm({
                                          title,
                                          initialValues = {},
                                          onSubmit = () => {},
                                      }: Props) {
    const [file, setFile] = useState<File>();
    const form = useForm<Partial<Company>>({
        validateInputOnChange: true,
        initialValues,
        validate: CompanyValidate,
        transformValues: (values) => {
            const fields = _omit(values, COMPANY_PROTECTED_FIELDS);
            return {
                ...fields,
                slug: fields.vat,
            };
        },
    });
    useUnloadAlert();
    const formActions = useFormActions(form, onSubmit, file);
    const handleFileChange = handleFileUpload(setFile);
    const { t } = useTranslation();
    let rtr = AppRouter.getInstance();

    let canc_url = rtr.getCancelToUrl();
    return (
        <Stack maw={1000} m="auto">
            {title && <Title>{t(title)}</Title>}

            <FormSection title="company_logo">
                <Box m="auto">
                    <ImagePreview value={file} defaultValue={form.values.profilePic} />
                    <InputFile onChange={handleFileChange} />
                </Box>
            </FormSection>
            <FormSection title="public_profile">
                <TextInput
                    my="md"
                    required
                    label={t("company_name")}
                    {...form.getInputProps("name")}
                />

                <InputUnique
                    fieldName="vat"
                    value={form.values.vat ?? ""}
                    setValue={(value: string) => {
                        form.setFieldValue("vat", value);
                    }}
                />

                <DescriptionInput
                    label={t("description")}
                    {...form.getInputProps("desc")}
                />
            </FormSection>

            <FormSection title={t("contact_details")}>
                <TextInput
                    required
                    my="md"
                    label={t("email")}
                    {...form.getInputProps("contact_mail")}
                />

                <TextInput
                    required
                    my="md"
                    type="string"
                    label={t("phone_number")}
                    placeholder="+39 123 1237 123 32"
                    {...form.getInputProps("contact_phone")}
                />

                <TextInput
                    my="md"
                    label={t("website")}
                    placeholder="https://example.com"
                    {...form.getInputProps("contact_website")}
                />
            </FormSection>
            <RegisterCompanyActions cancel_to={canc_url} actions={formActions} isReady={form.isValid()} />
        </Stack>
    );
}
