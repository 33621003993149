import _isEmpty from "lodash/isEmpty";
import {EmployeeRow} from "../../../components/EmployeeRow";
import Actions from "src/components/Actions";
import Employee from "../../../../types/Employee.type";
import NotFound from "../../../../components/NotFound";
import {useTranslation} from "react-i18next";

import {Flex, Button, useMantineTheme} from "@mantine/core"; // Importing useMantineTheme
import useIsOwn from "src/hooks/useIsOwn";
import EmployeeDetailHeader from "../../../components/EmployeeDetailHeader";
import CompanyBaseComponent from "../../../Company/component/CompanyBaseComponent";
import React, {useState} from "react";
import {EmployeeModel} from "../../../../models/EmployeeModel";
import Config from "../../../../classes/application/Config";
import {confirmAlert} from "react-confirm-alert"; // Importing the react-confirm-alert
//import "react-confirm-alert/src/react-confirm-alert.css"; // Import the CSS for the confirmation dialog
import './EmployeeDetailView.css';
import EmployeeHandler from "../../Handler/EmployeeHandler";
import EmployeeDetailActions from "./EmployeeDetailActions";
import JobOfferActions from "../../../JobOffer/view/detail/JobOfferActions"; // Import the CSS file
interface Props {
    employee: Employee;
}

export default function EmployeeDetailView({employee}: Props) {
    const isOwn = useIsOwn(employee);
    const {t} = useTranslation();
    const theme = useMantineTheme();

    if (!employee) {
        return <NotFound label={t("employee")}/>;
    }

    const {id} = employee;
    const eh = new EmployeeHandler();
    let cfg = Config.getInstance();
    let isPublic = cfg.isCurrentPublicUrl();
    let employee_model = new EmployeeModel();
    eh.setEmployee(employee_model);

    employee_model.setData(employee);

    let actionSubmit = {
        label: "submit_job",
        to: `/app/add/proposal/${id}`,
        hasConfirm: false,
        confirmTitle: t("registration_needed"),
        confirmMessage: t("the_registration_is_needed"),
        confirmButtonConfirm: t("do_register"),
        mode_to: "route",
    };

    if (isPublic) {
        actionSubmit = {
            label: "submit_job",
            to: `/app`,
            hasConfirm: true,
            confirmTitle: t("registration_needed"),
            confirmMessage: t("the_registration_is_needed"),
            mode_to: "window",
            confirmButtonConfirm: t("do_register"),
        };
    }

    const handleButtonClick = () => {
        if (isPublic) {

            confirmAlert({
                title: actionSubmit.confirmTitle,
                message: actionSubmit.confirmMessage,
                buttons: [
                    {
                        label: t(actionSubmit.confirmButtonConfirm),
                        onClick: () => {
                            window.location.href = actionSubmit.to; // Proceed if confirmed
                        },
                        style: {
                            backgroundColor: theme.colors.primary[6],
                            color: "white",
                            borderRadius: "4px", // Rounded corners
                            margin: "10px", // Adding margin to separate buttons
                            width: "150px", // Set width of the button
                            display: "inline-block", // Ensures button is inline
                            textAlign: "center", // Center text inside button
                        },
                    },
                    {
                        label: t("cancel").charAt(0).toUpperCase() + t("cancel").slice(1), // Capitalize cancel label
                        onClick: () => {
                        },
                        style: {
                            backgroundColor: theme.colors.gray[4], // Secondary color for cancel button background
                            color: "black",
                            margin: "10px", // Adding margin to separate buttons
                            width: "150px", // Set width of the cancel button
                            display: "inline-block", // Ensures button is inline
                            textAlign: "center", // Center text inside cancel button
                            borderRadius: "4px", // Rounded corners for cancel button as well
                        },
                    },
                ],
            });

        } else {
            window.location.href = actionSubmit.to; // For non-public, proceed without confirmation
        }
    };

    return (
        <div className="employee-detail">
            <EmployeeDetailHeader
                post={employee}
                employee={employee_model}
                isOwn={isOwn}
            />

            {!_isEmpty(employee?.Company) && !_isEmpty(employee?.Company?.desc) && (
                <EmployeeRow title={t("company") + ":"}>
                    <div
                        className="company-container"
                        style={{
                            width: "100%",
                        }}
                    >
                        <CompanyBaseComponent company={employee?.Company}/>
                        <br/><br/>
                    </div>
                </EmployeeRow>
            )}

            <Flex justify="center" align="center" style={{height: "6vh"}}>
                <EmployeeDetailActions
                    isPublic={isPublic}
                    actionSubmit={actionSubmit}
                    isOwn={isOwn}
                    id={id}
                />
            </Flex>
        </div>
    );
}
