import {ReactNode, useEffect, useState} from "react";
import AuthContext from "./auth.context";
import getToken from "src/utils/getToken";
import useCognitoUser from "src/hooks/useCognitoUser";
import AppRouter from "../classes/AppRouter";
import App from "../App";
import Config from "../classes/application/Config";

interface Props {
    children: ReactNode;
}

function AuthProvider({children}: Props) {
    const {cognitoUser, isLoading} = useCognitoUser();
    const [loading, setLoading] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [origin, setOrigin] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [surname, setSurname] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [token, setToken] = useState<string>("");

    useEffect(() => {
        if (!isLoading) {
            setEmail(cognitoUser?.email ?? "");
            setName(cognitoUser?.name ?? "");
            setSurname(cognitoUser?.surname ?? "");
            setPhone(cognitoUser?.phone ?? "");
            setOrigin(cognitoUser?.origin ?? "");
        }
    }, [isLoading, cognitoUser]);

    useEffect(() => {
        const func = async () => {
            // saves token
            const t = await getToken();
            if (t) {
                setToken(t);
                setLoading(false);
            }
        };

        let rtr = AppRouter.getInstance();

        if(email) {

            let cfg = Config.getInstance();
            let isPublic = cfg.isCurrentPublicUrl();
            if(isPublic) {
                // @ts-ignore
                window.public_redirecting=1;

                // @ts-ignore
                window.app_is_auth=1;
                rtr.redirectToApp(location.href,"from_public=1");
            }


        }


        if (email) {
            func();
        }
    }, [email]);

    const value = {
        isLoading: loading || isLoading,
        email,
        name,
        surname,
        phone,
        origin,
        token,
        setEmail,
        setToken,
    };

    return <><AuthContext.Provider value={value}>{children}</AuthContext.Provider></>
        ;
}

export default AuthProvider;
