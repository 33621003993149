import Loader from "../Loader";
import {Flex, Pagination} from "@mantine/core";
import {useState} from "react";
import EmployeeCard from "../cards/Employee.Card";
import Employee from "src/types/Employee.type";
import SearchFilter from "./SearchFilter";
import _map from "lodash/map";
import _isEmpty from "lodash/isEmpty";
import SearchNoResults from "./SearchNoResults";
import {SearchFilters} from "src/types/SearchFilters.type";
import {useGET} from "src/hooks/useApi";
import {PaginationResponse} from "src/types/Pagination.Type";
import Config from "../../classes/application/Config";
import usePublicApi, {usePublicGET} from "../../hooks/useApiPublic";

function encodeFilters(filter: SearchFilters): string {
    return new URLSearchParams(filter as Record<string, string>).toString();
}

const SearchEmployees = () => {
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState<SearchFilters>({});
    const params = encodeFilters(filters);
    const url = `search/employees?page=${page}&${params}`;
    let data: PaginationResponse<Employee> | undefined;
    let isLoading = true;
    let error: any = null;
    let cfg = Config.getInstance();

   // alert("form");

    /*
    ({ data, isLoading, error } = useGET<PaginationResponse<Employee>>(url, {
        isPublic: true,
    }));
    */

    if (cfg.isCurrentPublicUrl()) {

        ({data, isLoading, error} = usePublicGET<PaginationResponse<Employee>>(url, {
            isPublic: true,
        }));
    } else {
        ({data, isLoading, error} = useGET<PaginationResponse<Employee>>(url, {
            isPublic: true,
        }));
    }
    /*
     let { data, isLoading, error } = useGET<PaginationResponse<Employee>>(url, {
         isPublic: true,
     });
     */

//isLoading=false;
    return (
        <Flex direction="column">
            <SearchFilter setData={setFilters}/>
            <Flex direction="column">
                <Loader isLoading={isLoading} error={error} hasData={data?.total > 0}>
                    <Flex direction="row" justify="center" align="top" wrap="wrap">
                        {_isEmpty(data?.result) && (
                            <SearchNoResults resetFilters={() => setFilters({})}/>
                        )}
                        {_map(data?.result, (s: Employee) => (
                            <EmployeeCard key={s.id} employee={s}/>
                        ))}
                    </Flex>
                </Loader>
                <Flex direction="row" justify="center">
                    <Pagination value={page} onChange={setPage} total={data?.count}/>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SearchEmployees;
