import { createBrowserRouter } from "react-router-dom";
import React, { lazy, Suspense } from "react";
import Home from "src/pages/Home";
import Error from "src/pages/Error";
import appRoutes from "./app.routes";
import publicRoutes from "./public.routes";
import CompanySlug from "src/pages/CompanySlug";
import Register from "src/components/Register";
import RootLayout from "src/pages/Root.Layout";
import Public from "../modules/public/Public";
import MainLayout from "../pages/Main.Layout";
import PublicLayout from "../pages/Public.Layout";
import { RedirectCheck } from "../components/RedirectCheck";
import RegisterCompanyComponent from "../entity/Company/component/RegisterCompanyComponent/RegisterCompanyComponent"; // Import RedirectCheck

// Lazy load components
const ChatbotLayout = lazy(() => import("src/modules/chatbot/ChatbotLayout"));
const Chatbot = lazy(() => import("src/modules/chatbot/Chatbot"));

const routes = createBrowserRouter([
    {
        path: "/",
        element: <RootLayout />,
        errorElement: <Error showBackToHome />,
        children: [
            // Public routes with RedirectCheck first
            {
                path: "publicx",
                element: (
                    <Suspense fallback={<div>Loading Public Page...</div>}>
                        <RedirectCheck>
                            <Public />
                        </RedirectCheck>
                    </Suspense>
                ),
            },
            {
                path: "public/view/employeetest",
                element: (
                    <Suspense fallback={<div>Loading Public Page...</div>}>
                        <RedirectCheck>
                            <Public />
                        </RedirectCheck>
                    </Suspense>
                ),
            },
            {
                path: "public",
                element: (
                    <Suspense fallback={<div>Loading Public Page...</div>}>
                        <RedirectCheck>
                            <PublicLayout />
                        </RedirectCheck>
                    </Suspense>
                ),
            },
            // Authenticated routes come after public routes
            {
                index: true,
                element: <Home />,
            },
            publicRoutes,
            appRoutes,
            {
                path: "register",
                element: <Register />,
            },
            {
                path: "app/register",
                element: <Register />,
            }
            ,
            {
                path: "company/register",
                element: <RegisterCompanyComponent />,
            }
            ,
            {
                path: "c/:slug",
                element: <CompanySlug />,
            },
            {
                path: "chatbot",
                element: (
                    <Suspense fallback={<div>Loading Chatbot...</div>}>
                        <ChatbotLayout />
                    </Suspense>
                ),
                children: [
                    {
                        index: true,
                        element: (
                            <Suspense fallback={<div>Loading Chatbot...</div>}>
                                <Chatbot />
                            </Suspense>
                        ),
                    },
                ],
            },
        ],
    },
]);

export default routes;
