import {Divider, Flex, Grid, GridCol, Text, Title, TitleOrder} from "@mantine/core";
import {ReactNode, CSSProperties} from "react";
import useIsMobile from "src/hooks/useIsMobile";
import "./EmployeeRow.css"; // Import the CSS file

interface Props {
    children: ReactNode;
    title?: ReactNode;
    desc?: string;
    order?: TitleOrder;
    centerContent?: boolean; // Optional prop to center the content
    width?: string | number; // Optional prop for setting width
    className?: string; // Allow custom styles
    titleStyle?: CSSProperties; // Optional prop for title styles
    Style?: CSSProperties; // Optional prop for children styles
}

export const EmployeeRow = ({
                                children,
                                title,
                                desc,
                                order = 4,
                                centerContent = true, // Default to true if not provided
                                width = "100%", // Default width to 100%
                                className = "", // Default to an empty string
                                titleStyle = {}, // Default to an empty object
                                Style = {}, // Default to an empty object
                            }: Props) => {
    const isMobile = useIsMobile();
//    const cols = isMobile ? 1 : 12;

    const cols = 12;

    return (
        <div className={`employee-row ${className}`}>

            <Grid align="stretch" columns={cols} gutter="xs">
                {(title || desc) && (
                    <GridCol span={12}>
                        <Flex direction="column"
                              align={centerContent ? "center" : "flex-start"}
                            /*
                                  mb="sm"
    */
                        > {/* Adjust spacing here if needed */}
                            {title && (
                                <div className="employee-row-group-title">
                                    <Title
                                        order={order}
                                        className="employee-row-title"
                                        style={titleStyle} // Apply the title styles


                                    >
                                        {title}
                                    </Title>
                                </div>

                            )}
                            {desc && <Text size="sm" className="employee-row-desc">{desc}</Text>}
                        </Flex>
                    </GridCol>
                )}
                <GridCol span={12}>
                    <Flex
                        wrap="wrap"
                        direction="row"
                        justify={centerContent ? "center" : "flex-start"}
                        align={centerContent ? "center" : "flex-start"}
                        className="employee-row-content"
                        style={{...Style, width}} // Apply children styles and width
                    >
                        {children}
                    </Flex>
                </GridCol>
            </Grid>

        </div>
    );
};
