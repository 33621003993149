import API from "../helpers/api";
import useSWR from "swr";
import { useMemo } from "react";
import { methodEnum } from "src/types/Method.type";
import useAuth from "./useAuth";

export const usePublicApi = () => {
  const { token } = useAuth();

  return useMemo(() => API(token), [token]);
};

export default usePublicApi;

export function usePublicGET<T>(
    url: string | null,
    opts: { isPublic?: boolean } = { isPublic: true } // Default isPublic to true
) {
  const { token } = useAuth();
  const api = usePublicApi();
  const key = opts.isPublic || token ? url : null;

  const { data, isLoading, error, mutate } = useSWR(key, api[methodEnum.GET], {
    errorRetryInterval: 4000,
    errorRetryCount: 5,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  });

  return {
    data: data as T,
    isLoading: isLoading,
    error,
    mutate,
  };
}
