import { Divider, Flex, Grid, GridCol, Text, Title, TitleOrder } from "@mantine/core";
import { ReactNode } from "react";
import useIsMobile from "src/hooks/useIsMobile";

interface Props {
  children: ReactNode;
  title?: ReactNode;
  desc?: string;
  order?: TitleOrder;
  excludeDivider?: boolean; // New parameter to exclude Divider
}

export const Row = ({
                      children,
                      title,
                      desc,
                      order = 4,
                      excludeDivider = false, // Defaults to false (Divider is included by default)
                    }: Props) => {
  const isMobile = useIsMobile();
  const cols = isMobile ? 1 : 12;
  const offset = isMobile ? 1 : 3;

  return (
      <>
        <Grid align="stretch" columns={cols}>
          {(title || desc) && (
              <GridCol span={offset}>
                <Flex justify={isMobile ? "center" : "flex-end"} direction="column">
                  <Title order={order} mx={isMobile ? "auto" : "xs"}>
                    {title}
                  </Title>
                  <Text size="sm">{desc}</Text>
                </Flex>
              </GridCol>
          )}
          <GridCol span="auto">
            <Flex
                wrap="wrap"
                direction="row"
                justify={isMobile ? "center" : "left"}
                mb="md"
            >
              {children}
            </Flex>
          </GridCol>
        </Grid>
        {!excludeDivider && <Divider my="md" />} {/* Conditionally render Divider */}
      </>
  );
};
