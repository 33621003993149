import { useNavigate, useParams } from "react-router-dom";
import ProposalForm from "src/components/forms/Proposal.form";
import Proposal from "src/types/Proposal.type";
import useApi, { useGET } from "src/hooks/useApi";
import Loader from "src/components/Loader";
import Employee from "src/types/Employee.type";
import CONFIG_KEYS from "src/constants/config.const";
import usePageView from "../../../hooks/usePageView";
import TrackingSystem from "../../../lib/Tracking/TrackingSystem";

export default function AddProposal() {
  const navigate = useNavigate();
  usePageView("form_proposal");
  const api = useApi();
  const { employeeId } = useParams();
  const url = `res/employee/${employeeId}`;
  const { data, isLoading, error } = useGET<Employee>(url);

  const initialValues: Partial<Proposal> = {
    employeeId,
    toEmployee: data,
  };

  const onSubmit = async (values: Partial<Proposal>) => {
    const res = await api.POST(`res/proposal`, values);
    if (res?.success) {

      let ts = TrackingSystem.getInstance().sendCustomEvent("proposal_added");
      navigate("/app/section/proposals_sent");
    }
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <ProposalForm
        title="create_proposal"
        initialValues={initialValues}
        onSubmit={onSubmit}
        costId={CONFIG_KEYS.ADD_PROPOSAL_COST}
        expiryId={CONFIG_KEYS.PROPOSAL_EXPIRY_PERIOD}
      />
    </Loader>
  );
}
