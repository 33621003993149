import React from "react";
import {Flex} from "@mantine/core";
import useIsMobile from "../../hooks/useIsMobile";

interface DualItemWrapperProps {
    children: React.ReactNode[]; // Exactly two children are expected
    gap?: string; // Optional gap between the items
    className?: string; // Optional custom className
    style?: React.CSSProperties; // Optional custom styles
}

const DualItemWrapperComponent: React.FC<DualItemWrapperProps> = ({
                                                                      children,
                                                                      gap = "md",
                                                                      className = "",
                                                                      style = {},
                                                                  }) => {
    if (!children || React.Children.count(children) !== 2) {
        console.error("DualItemWrapperComponent expects exactly two children.");
        return null;
    }

    const isMobile = useIsMobile();

    // Inject the "equal-height" class into children if they have a `className` property
    const childrenWithEqualHeight = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, {
                ...(child.props as any), // Preserve all existing props
                className: `${(child.props as any).className || ""} equal-height`.trim(),
            });
        }
        return child;
    });

    return isMobile ? (
        // Render children stacked vertically on mobile
        <>
            {
                children[0]
            }
            {
                children[1]
            }
        </>
    ) : (
        // Render Flex layout for larger screens
        <Flex
            justify="center"
            wrap="wrap"
            gap={gap}
            className={`equal-height-wrapper ${className}`}
            style={style}
        >
            {childrenWithEqualHeight}
        </Flex>
    );
};

export default DualItemWrapperComponent;
