import Config from "./application/Config";
import AppRouter from "./AppRouter";

class Application {
    private static instance: Application;
    private readonly config: Config;
    protected env: any;

    protected _user: any;

    private constructor(env = null) {
        if (env !== null) {
            this.env = env;
        }
        this._user = undefined;
        this.config = Config.getInstance(env);
    }

    public runOverlay (duration: number = 4000) {
        const overlay = document.getElementById("fade-overlay");
        if (overlay) {
            overlay.classList.add("opacity-100");
            overlay.classList.remove("opacity-0", "pointer-events-none");

            setTimeout(() => {
                overlay.classList.add("opacity-0");
                overlay.classList.remove("opacity-100");
            }, duration);
        }
    };

    public setUser(user: any) {

        let cfg = Config.getInstance();
        let isPublic = cfg.isCurrentPublicUrl();

        if(isPublic) {
            if(user) {
               let rtr=  AppRouter.getInstance();
               rtr.redirectToAppIfNeeded(location.href);

              // alert("redir there");
            }
        }

        this._user = user;
    }

    public isAuth(): boolean {
        if (this._user !== undefined) {
            return true;
        }
        return false;
    }


    public hasCompany():boolean {
        return this.getUser().companyId;
    }

    public getUser(): any {
        return this._user;
    }

    public static getInstance(env: any = null): Application {
        if (!Application.instance) {
            Application.instance = new Application(env);
        }
        return Application.instance;
    }

    public static getConfig(): Config {
        return Application.getInstance().getConfig();
    }

    public getConfig(): Config {
        return this.config;
    }
}

export default Application;