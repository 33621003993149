import React, { useState, useEffect } from "react";

interface FadeOverlayProps {
    duration?: number; // Duration of the fade effect (optional)
    trigger?: boolean; // Optional trigger prop for React usage
}

const FadeOverlayComponent: React.FC<FadeOverlayProps> = ({ duration = 4000, trigger }: FadeOverlayProps) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        // React-based trigger
        if (trigger) {
            setVisible(true);
            setTimeout(() => {
                setVisible(false);
            }, duration);
        }
    }, [trigger, duration]);

    return (
        <div
            id="fade-overlay"
            className={`fixed inset-0 bg-black transition-opacity duration-[${duration}ms] ${
                visible ? "opacity-100" : "opacity-0 pointer-events-none"
            }`}
            style={{ zIndex: 9999 }}
        />
    );
};

export default FadeOverlayComponent;
