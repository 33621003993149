import SimpleTable from "./SimpleTable";
import JobOfferTableRow from "./rows/JobOfferTable.Row";
import JobOffer from "src/types/JobOffer.type";
import usePageView from "../../hooks/usePageView";

interface Props {
  jobOffers: JobOffer[];
}
const cols = ["title", "level", "fields", "availability", "status", ""];

function JobOfferTable({ jobOffers }: Props) {

  usePageView("joboffer_list");
  return (
    <SimpleTable<JobOffer>
      section="job"
      rows={jobOffers}
      cols={cols}
      Row={JobOfferTableRow}
    />
  );
}

export default JobOfferTable;
