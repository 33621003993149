import { EmployeeModel } from "../../../models/EmployeeModel";

class EmployeeHandler {
    private static instance: EmployeeHandler;
    private skillCount: number;

    // Make _employee optional
    protected _employee?: EmployeeModel;

    // Getter with a fallback if the employee is undefined
    public getEmployee(): EmployeeModel | undefined {
        return this._employee;
    }

    // Setter to allow setting the employee
    public setEmployee(employee?: EmployeeModel): void {
        this._employee = employee;
    }

    public constructor() {
        this.skillCount = 0; // Initialize skill count
    }

    public static getInstance(): EmployeeHandler {
        if (!EmployeeHandler.instance) {
            EmployeeHandler.instance = new EmployeeHandler();
        }
        return EmployeeHandler.instance;
    }

    public addSkillCount(count: number): void {
        let ct = this.getSkillCount();
        ct = count + ct;
        this.skillCount = ct;
    }

    public getSkillCount(): number {
        return this.skillCount;
    }

    public setSkillCount(count: number): void {
        if (count < 0) {
            throw new Error("Skill count cannot be negative.");
        }
        this.skillCount = count;
    }

    // Method to reset the skill count to 0
    public reset(): void {
        this.skillCount = 0;
    }
}

export default EmployeeHandler;
