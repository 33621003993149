import {Checkbox, Flex, Text, UnstyledButton} from "@mantine/core";
import _map from "lodash/map";
import _get from "lodash/get";
import _set from "lodash/set";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import _each from "lodash/each";
import {useMemo} from "react";
import {InputMatrixOption} from "src/types/InputMatrixOption";
import {IconCircleFilled} from "@tabler/icons-react";

interface Props {
    values: string[];
    setValues: (a: string[]) => void;
    readonly?: boolean;
    availableValues: InputMatrixOption[];
}

const EmployeeSkillMatrixComponent = ({
                            values,
                            setValues,
                            availableValues,
                            readonly = false,
                        }: Props) => {
    const objectValues = useMemo(() => {
        const obj = {};
        _each(availableValues, ({value: v}) => {
            _set(obj, [v], _includes(values, v));
        });
        return obj;
    }, [values, availableValues]);

    const handleChange = (id: string) => {
        const prev = _get(objectValues, [id], false);
        const res = prev ? _filter(values, (v) => v !== id) : [...values, id];
        setValues(res);
    };

    let wdt: string;
    wdt = "200px"
    return (
        <div

            style={{display: "flex", flexWrap: "wrap", alignItems: "flex-start"}}>
            {_map(availableValues, ({label, value}) => {
                const v = _get(objectValues, [value], false);
                if (readonly && !v) {
                    return null;
                }
                return (
                    <div
                        key={value} // Add the key prop here

                        style={{
                            textAlign: "left",
                            marginBottom: "10px", // Optional: Adds space between rows
                        }}
                    >

                        <UnstyledButton
                            key={value}
                            px="xs"
                            onClick={() => handleChange(value)}
                            /*
                                width={
                                wdt
                            } // Fixed width for each column
    */
                        >

                            <Flex h={50} w={200} align="center">
                                {readonly ? (
                                    <Text c="primary" mr="xs">
                                        <IconCircleFilled size={15}/>
                                    </Text>
                                ) : (
                                    <Checkbox pr="xs" readOnly checked={v}/>
                                )}
                                {label}
                            </Flex>
                        </UnstyledButton>
                    </div>

                );
            })}
        </div>
    );
};

export default EmployeeSkillMatrixComponent;
