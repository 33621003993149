import {IconPlus} from "@tabler/icons-react";
import Actions from "src/components/Actions";
import Loader from "src/components/Loader";
import {EmployeeTable} from "src/components/tables/EmployeeTable";
import {useGET} from "src/hooks/useApi";
import Employee from "src/types/Employee.type";
import Config from "../../../classes/application/Config";
import ActionButton from "../../../components/ActionButton";
import InfoBox from "../../../components/ui/widget/InfoBox/InfoBox";
import useIsMobile from "../../../hooks/useIsMobile";
import {useTranslation} from "react-i18next";
import _map from "lodash/map";
import {Flex, Paper, Title} from "@mantine/core";
import {useState} from "react";
import {confirmAlert} from "react-confirm-alert";
import {JobOfferStatus as JobOfferStatusType} from "../../../types/JobOfferStatus.type"; // Importing the react-confirm-alert

const ListEmployee = () => {

    // const navigate = useNavigate();

    // const {t} = useTranslation();
    const isMobile = useIsMobile();
    const direction = isMobile ? "column" : "row";
    //  const {actions = [], title = "", center, isReady = true} = props;

    let title = "test"; // Added translation for 'test'
    const { t } = useTranslation();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [confirmTo, setConfirmTo] = useState("");
    const [confirmTitle, setConfirmTitle] = useState<string>("");
    const [confirmMessage, setConfirmMessage] = useState<string>("");
    const [confirmButtonConfirm, setConfirmButtonConfirm] = useState<string>("");
    const [modeTo, setModeTo] = useState<string>("");

    const style = {
        background: title
            ? "linear-gradient(90deg, var(--mantine-color-primary-filled), transparent)"
            : "transparent",
        boxShadow: "none",
        border: "none",
    };
    let center: boolean = true;

    const cfg = Config.getInstance();

    let actionSubmit:any = {
        label: t("apply_now"), // Translated "apply_now"
        // to: `/app/add/application/${id}`,
        disabled: status !== JobOfferStatusType.open,
        hasConfirm: false,
        confirmTitle: t("registration_needed"), // Translated "registration_needed"
        confirmMessage: t("the_registration_is_needed"), // Translated "the_registration_is_needed"
        confirmButtonConfirm: t("do_register"), // Translated "do_register"
        mode_to: "route",
    };

    if (true) {
        actionSubmit = {
            label: t("apply_now"), // Translated "apply_now"
            to: `/app`,
            hasConfirm: true,
            confirmTitle: t("registration_needed"), // Translated "registration_needed"
            confirmMessage: t("the_registration_is_needed"), // Translated "the_registration_is_needed"
            mode_to: "window",
            confirmButtonConfirm: t("do_register"), // Translated "do_register"
            disabled: status !== JobOfferStatusType.open,
        };
    }
    if(cfg.hasProgressiveRegister()) {

        confirmAlert({
            title: actionSubmit.confirmTitle,
            message: actionSubmit.confirmMessage,
            buttons: [
                {
                    label: actionSubmit.confirmButtonConfirm,
                    onClick: () => {
                        window.location.href = actionSubmit.to; // Proceed if confirmed
                    },
                    style: {
                        //    backgroundColor: theme.colors.primary[6],
                        color: "white",
                        borderRadius: "4px", // Rounded corners
                        margin: "10px", // Adding margin to separate buttons
                        width: "150px", // Set width of the button
                        display: "inline-block", // Ensures button is inline
                        textAlign: "center", // Center text inside button
                    },
                },
                {
                    //    label: t("cancel").charAt(0).toUpperCase() + t("cancel").slice(1), // Capitalize cancel label
                    label: t("cancel"), // Translated "cancel"
                    onClick: () => {
                    },
                    style: {
                        //       backgroundColor: theme.colors.gray[4], // Secondary color for cancel button background
                        color: "black",
                        margin: "10px", // Adding margin to separate buttons
                        width: "150px", // Set width of the cancel button
                        display: "inline-block", // Ensures button is inline
                        textAlign: "center", // Center text inside cancel button
                        borderRadius: "4px", // Rounded corners for cancel button as well
                    },
                },
            ],
        });
    }

    let infoboxcode: string = "infobox_developers";
    if (cfg.hasProgressiveRegister()) {
        let data: Employee[] = [];
        return <>
            <Paper c="white" px="md" py="xs" mb="xl">

                <Flex justify="space-between" align="center" direction={direction}>
                    <Flex direction="row" align="center" my="md" gap="xs">
                        {/* Trigger InfoBox Modal */}
                        {typeof infoboxcode === "string" && (
                            <InfoBox
                                code={"infobox_developers"}
                                title={t("developers_info")} // Translated title for info box
                                content={t("info_content")} // Translated content for info box
                            />
                        )}

                        <Title order={4} tt="uppercase">
                            {t("employee_title")} {/* Translated "employee_title" */}
                        </Title>
                    </Flex>

                    <Flex
                        direction={direction}
                        justify={center || isMobile ? "center" : "end"}
                        gap="sm"
                        wrap="wrap"
                    >
                        <ActionButton isReady={true}

                                      to={"/app/add/employee"}
                                      label={"add_new"}
                                      Icon={IconPlus
                                      }

                        >


                        </ActionButton>
                    </Flex>
                </Flex>
            </Paper>

            <EmployeeTable employees={data}/>
        </>
    }

    const {data, isLoading, error} = useGET<Employee[]>(`res/employee`);

    return (
        <>
            <Loader isLoading={isLoading} error={error} hasData={!!data}>
                <Actions
                    title={t("my_employees")} // Translated "my_employees"
                    actions={[
                        {to: "/app/add/employee", label: t("add_new"), Icon: IconPlus}, // Translated "add_new"
                    ]}
                    infoboxCode={"infobox_developers"}
                />
                <EmployeeTable employees={data}/>
            </Loader>
        </>
    );
};

export default ListEmployee;
