export class ConfirmDialogParams {
    private _title: string;
    private _message: string;
    private _confirmButtonLabel: string;
    private _cancelButtonLabel: string;
    private _onConfirm: (() => void) | null;
    private _onCancel: (() => void) | null;

    private _closeModal: (() => void) | null;
    private _openModal: (() => void) | null;

    constructor(
        title: string | null = "",
        message: string | null = "",
        confirmButtonLabel: string | null = "",
        cancelButtonLabel: string | null = "",
        onConfirm: (() => void) | null = null,
        onCancel: (() => void) | null = null,
        openModal: (() => void) | null = null,
        closeModal: (() => void) | null = null
    ) {
        this._title = title ?? "";
        this._message = message ?? "";
        this._confirmButtonLabel = confirmButtonLabel ?? "";
        this._cancelButtonLabel = cancelButtonLabel ?? "";
        this._onConfirm = onConfirm;
        this._onCancel = onCancel;
        this._openModal = openModal;
        this._closeModal = closeModal;
    }

    // Getter and Setter for Title
    getTitle(): string {
        return this._title;
    }

    setTitle(title: string): void {
        this._title = title;
    }

    // Getter and Setter for Message
    getMessage(): string {
        return this._message;
    }

    setMessage(message: string): void {
        this._message = message;
    }

    // Getter and Setter for Confirm Button Label
    getConfirmButtonLabel(): string {
        return this._confirmButtonLabel;
    }

    setConfirmButtonLabel(confirmButtonLabel: string): void {
        this._confirmButtonLabel = confirmButtonLabel;
    }

    // Getter and Setter for Cancel Button Label
    getCancelButtonLabel(): string {
        return this._cancelButtonLabel;
    }

    setCancelButtonLabel(cancelButtonLabel: string): void {
        this._cancelButtonLabel = cancelButtonLabel;
    }

    // Getter and Setter for OnConfirm
    getOnConfirm(): (() => void) | null {
        return this._onConfirm;
    }

    setOnConfirm(onConfirm: (() => void) | null): void {
        this._onConfirm = onConfirm;
    }

    // Getter and Setter for OnCancel
    getOnCancel(): (() => void) | null {
        return this._onCancel;
    }

    setOnCancel(onCancel: (() => void) | null): void {
        this._onCancel = onCancel;
    }

    // Getter and Setter for Open Modal
    getOpenModal(): (() => void) | null {
        return this._openModal;
    }

    setOpenModal(openModal: (() => void) | null): void {
        this._openModal = openModal;
    }

    // Getter and Setter for Close Modal
    getCloseModal(): (() => void) | null {
        return this._closeModal;
    }

    setCloseModal(closeModal: (() => void) | null): void {
        this._closeModal = closeModal;
    }
}
