import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useGET} from "src/hooks/useApi";

const CompanySlug = () => {
    const {slug} = useParams();
    const navigate = useNavigate();
    const {data, error, isLoading} = useGET(`search/company/${slug}`);
    const companyId = data ?? null;
    const {t} = useTranslation();

    useEffect(() => {
        if (companyId === null && !error && isLoading) {
            return;
        }
        companyId ? navigate(`/app/view/company/${companyId}`) : navigate(`/app`);
    }, [companyId, error, isLoading, navigate]);

    return <>{t("redirecting_to", {slug})}</>;
};

export default CompanySlug;
