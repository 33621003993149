import { Badge, NavLink, Space } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { IconChevronRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { SidebarLink } from "src/types/SidebarLinks";
import Config from "../classes/application/Config";

interface Props {
    link: SidebarLink;
}

function NavbarLink({ link }: Props) {
    const { t } = useTranslation();
    const { icon = <Space w={30} /> } = link;
    const location = useLocation();
    const isCurrent = (path: string) => location.pathname === path;
    const cfg = Config.getInstance();
    const isPublic = cfg.isCurrentPublicUrl();

    // Adjust the link.to property dynamically

    let adjustedLinkTo:string|undefined = link.to;
    if (link.public) {
        adjustedLinkTo =
            isPublic && link.to?.includes("/app/") ? link.to.replace(/\/app\//, "/public/") : link.to;
    }


    //  alert(adjustedLinkTo);
    // Determine if the link should be disabled
    const isDisabled = isPublic && (link.public === false || link.public === undefined);

    return (
        <NavLink
            fw={800}
            active
            variant="filled"
            autoContrast={isCurrent(adjustedLinkTo ?? "")}
            to={adjustedLinkTo ?? ""}
            bg={isCurrent(adjustedLinkTo ?? "") ? "white" : "transparent"}
            component={Link}
            disabled={isDisabled} // Apply the disabled condition
            leftSection={icon}
            rightSection={
                link.notifications ? (
                    <Badge size="sm" variant="filled">
                        {link.notifications}
                    </Badge>
                ) : (
                    <IconChevronRight size={14} />
                )
            }
            label={t(link.label)}
            childrenOffset={42}
        />
    );
}

export default NavbarLink;
