import { useParams } from "react-router-dom";
import Loader from "src/components/Loader";
import CompanyView from "src/components/view/Company.view";
import { useGET } from "src/hooks/useApi";
import Company from "src/types/Company.type";
import usePageView from "../../../hooks/usePageView";

export default function ViewCompanyPage() {
  const params = useParams();


  const url = `res/company/${params.id}`;
  const { data, isLoading, error } = useGET<Company>(url);

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <CompanyView company={data} />
    </Loader>
  );
}
