import { ReactNode } from "react";
import useAuth from "src/hooks/useAuth";
import Loading from "src/pages/Loading";
import Error from "src/pages/Error";
import Config from "../classes/application/Config";

interface Props {
  isLoading?: boolean;
  hasData?: boolean;
  error?: object | string;
  children: ReactNode;
}

const Loader = (props: Props) => {
  let { isLoading, error, hasData = true, children } = props;
  const { token } = useAuth();

  let cfg = Config.getInstance();

  let isPublic=cfg.isCurrentPublicUrl();

  if (!token && !isPublic) {

    return <Loading />;
  }

  if (isLoading) {

    return <Loading />;
  }


  if (error) {
    return <Error desc={error.toString()} showBackToHome />;
  }

  if (!hasData) {
    return (
      <Error title="no_data_found" desc={error?.toString()} showBackToHome />
    );
  }

  return <>{children}</>;
};

export default Loader;
