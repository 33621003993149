import _get from "lodash/get";
import _map from "lodash/map";
import {
    MultiSelect,
    MultiSelectProps,
    Select,
    SelectProps,
} from "@mantine/core";
import {useGET} from "src/hooks/useApi";
import {useTranslation} from "react-i18next";
import {usePublicGET} from "../hooks/useApiPublic";
import Config from "../classes/application/Config";

type SelectId =
    | "fields"
    | "skills"
    | "languages"
    | "countries"
    | "zones"
    | "roles"
    | "visas"
    | "hourlyRates"
    | "levels";

interface Props {
    source: SelectId;
    inputProps: SelectProps & MultiSelectProps;
    label?: string;
    single?: boolean;
    required?: boolean;
}

const CustomSelect = (props: Props) => {
    const {source, inputProps, label, single = false, required = false} = props;

    let cfg = Config.getInstance();

    let isPublic = cfg.isCurrentPublicUrl();

    let data: any = null;

    if (isPublic) {
        //alert("goui");
        ({data} = usePublicGET(`static/${source}`));
    } else {
        ({data} = useGET(`static/${source}`));
    }


    const {t} = useTranslation();
    const labelContent = label || source;
    const options = _map(data as unknown[], (o) => ({
        value: _get(o, "value", o as string),
        label: t(_get(o, "label", o as string)),
    }));

    if (single) {
        // this ensures that fields stored as numbers
        // gets correctly applied as initial values
        const valueAsString = `${inputProps?.value}`;

        return (
            <Select
                my="md"
                required={required}
                label={t(labelContent)}
                searchable
                data={options}
                {...inputProps}
                value={valueAsString}
            />
        );
    }

    return (
        <MultiSelect
            my="md"
            searchable
            hidePickedOptions
            checkIconPosition="right"
            label={t(labelContent)}
            data={options}
            {...inputProps}
        />
    );
};

export default CustomSelect;
