import {Badge} from "@mantine/core";
import {useTranslation} from "react-i18next";
import useUser from "src/hooks/useUser";
import Company from "src/types/Company.type";

interface Props {
    company: Company;
}

const CompanyStatus = (props: Props) => {
    const {company} = props;
    const {t} = useTranslation();
    const {user} = useUser();

    let isOwn = false;

    if (user && company) {
        if (user.companyId && company.id) {
            isOwn = user.companyId === company.id;
        }

    }
    const activation = company?.account_activation ?? 0;

    if (isOwn && activation < 100) {
        return (
            <Badge color="orange">
                {/* TODO CHECK IF INTERPOLATED TRANSLATION IS REQUIRED */}
                {t("account_under_verification")} ({company.account_activation}%)
            </Badge>
        );
    }

    return <Badge color="green">{t("verified")}</Badge>;
};

export default CompanyStatus;
