import {MenuBarHandler} from "./MenuBarHandler";


export class LayoutHandler {
    private static instance: LayoutHandler;
    private _navbarState: 'opened' | 'closed' = 'closed';

    private constructor() {
    }

    static getInstance(): LayoutHandler {
        if (!LayoutHandler.instance) {
            LayoutHandler.instance = new LayoutHandler();
        }
        return LayoutHandler.instance;
    }

    isOpened(): boolean {
        return this._navbarState === 'opened';
    }

    isClosed(): boolean {
        return this._navbarState === 'closed';
    }

    setNavbarState(state: 'opened' | 'closed'): void {
        this._navbarState = state;
    }

    isMobile(): boolean {
        return window.innerWidth <= 768;
    }

    isMobileDevice() {
        return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };


    getMenuBar(): MenuBarHandler {
        return MenuBarHandler.getInstance();
    }
}

export default LayoutHandler;
