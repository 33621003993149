import { useNavigate, useParams } from "react-router-dom";
import CompanyForm from "src/components/forms/Company.form";
import Loader from "src/components/Loader";
import { useApi, useGET } from "src/hooks/useApi";
import Company from "src/types/Company.type";
import usePageView from "../../../hooks/usePageView";

export default function EditCompany() {
  const navigate = useNavigate();
  const { id } = useParams();


  usePageView("form_company_edit");
  const api = useApi();
  const url = `res/company/${id}`;
  const { data, isLoading, error } = useGET<Company>(url);

  const onSubmit = async (values: Partial<Company>, file?: File) => {
    if (file) {
      const res = await api.UPLOAD(file);
      values.profilePic = res.name;
    }
    const res = await api.PUT(`res/company/${id}`, values);
    if (res?.success) {
      navigate(`/app/view/company/${id}`);
    }
  };

  return (
    <Loader isLoading={isLoading} error={error} hasData={!!data}>
      <CompanyForm
        title="update_company"
        initialValues={data}
        onSubmit={onSubmit}
      />
    </Loader>
  );
}
