import {useParams} from "react-router-dom";
import EmployeeView from "src/components/view/Employee.view";
import Loader from "src/components/Loader";
import Employee from "src/types/Employee.type";
import {useGET} from "src/hooks/useApi";
import {Url} from "../../../lib/http/Url";
import EmployeeDetailView from "../../../entity/Employee/view/detail/EmployeeDetailView";
import Config from "../../../classes/application/Config";
import {usePublicGET} from "../../../hooks/useApiPublic";
import {PaginationResponse} from "../../../types/Pagination.Type";
import debug from "../../../components/Debug";
import usePageView from "../../../hooks/usePageView";
import Application from "../../../classes/Application";
import AppRouter from "../../../classes/AppRouter";

export default function ViewEmployeePage() {
    const params = useParams();
    const url = `res/employee/${params.id}`;
    //const { data, isLoading, error } = useGET<Employee>(url);


    usePageView("eployee");

    let data: any = undefined;
    let isLoading = true;
    let error: any = null;

    let cfg = Config.getInstance()

    if (cfg.isCurrentPublicUrl()) {



        ({data, isLoading, error} = usePublicGET<PaginationResponse<Employee>>(url, {
            isPublic: true,
        }));
        /*
        try {

            ({data, isLoading, error} = usePublicGET<PaginationResponse<Employee>>(url, {
                isPublic: true,
            }));
        }
        catch (e)
        {
            alert(error);
        }
        alert("done");

        return (
            <>
                cazzi 4
            </>
        );
        */

    } else {
        ({data, isLoading, error} = useGET<PaginationResponse<Employee>>(url, {
            isPublic: true,
        }));
    }

    let burl = Url.fromBrowser();
    let mode = "original";

    if (burl.queryParamIs("mode", "new")) {
        mode = "new";
    }
    if (cfg.hasPublicAreaEnabled()) {
        mode = "new";
    }
    mode="new";

    if (burl.queryParamIs("mode", "old")) {
        mode = "old";
    }
    let rtr = AppRouter.getInstance();
    if (location.href.includes("from_public") && rtr.needRedirect(location.href)) {
        if (error !== undefined) {
            isLoading = true;
            error = undefined;
        }
    }
    return (
        <>
            <Loader isLoading={isLoading} error={error} hasData={!!data}>
            {mode === "new" ? (
                    <EmployeeDetailView employee={data}/>
                ) : (
                    <EmployeeView employee={data}/>
                )}
            </Loader>
        </>
    );
}
