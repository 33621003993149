import { IconPlus } from "@tabler/icons-react";
import Actions from "../../../../components/Actions";
import Loader from "src/components/Loader";
import { EmployeeTable } from "../../../../components/tables/EmployeeTable";
import { useGET } from "src/hooks/useApi";
import Employee from "src/types/Employee.type";
import Config from "../../../../classes/application/Config";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import useIsMobile from "../../../../hooks/useIsMobile";
import { JobOfferStatus as JobOfferStatusType } from "../../../../types/JobOfferStatus.type";
import EmployeeListTopBarComponent from "../../component/EmployeeList/EmployeeListTopBarComponent";

const EmployeeListView = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const direction = isMobile ? "column" : "row";
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [confirmTo, setConfirmTo] = useState("");
    const [confirmTitle, setConfirmTitle] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const [confirmButtonConfirm, setConfirmButtonConfirm] = useState("");
    const [modeTo, setModeTo] = useState("");

    const cfg = Config.getInstance();

    if (cfg.hasProgressiveRegister()) {
        let data: Employee[] = [];
        return (
            <>
                <EmployeeListTopBarComponent/>
                <EmployeeTable employees={data} />
            </>
        );
    }

    const { data, isLoading, error } = useGET<Employee[]>("res/employee");
    return (
        <>
            <Loader isLoading={isLoading} error={error} hasData={!!data}>
                <Actions
                    title={t("my_employees")} // Translated "my_employees"
                    actions={[
                        {to: "/app/add/employee", label: t("add_new"), Icon: IconPlus}, // Translated "add_new"
                    ]}
                    infoboxCode={"infobox_developers"}
                />
                <EmployeeTable employees={data} />
            </Loader>
        </>
    );
};

export default EmployeeListView;
