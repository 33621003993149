import {Accordion, AccordionItem, AccordionControl, AccordionPanel, Title} from "@mantine/core";
import {ReactNode, CSSProperties, useEffect, useState} from "react";
import {EmployeeRow} from "./EmployeeRow";
import EmployeeHandler from "../Employee/Handler/EmployeeHandler";
import {EmployeeModel} from "../../models/EmployeeModel";
import {IconChevronDown} from '@tabler/icons-react';
import {IconChevronUp} from '@tabler/icons-react';
import "./EmployeeAccordionRow.css";

import {IconPlus} from '@tabler/icons-react';

import {
    useMantineColorScheme,
    useMantineTheme,
} from "@mantine/core";

interface AccordionSection {
    value: string;
    title: ReactNode;
    content: ReactNode;
    titleStyle?: CSSProperties;
}

interface EmployeeAccordionProps {
    sections: AccordionSection[];
    employee: EmployeeModel;
}

const EmployeeAccordion = ({sections, employee}: EmployeeAccordionProps) => {
    const eh = EmployeeHandler.getInstance();
    const theme = useMantineTheme();

    return (
        <Accordion
            chevronPosition={
                undefined
            }

            style={{
                paddingBottom: "8px", // Add padding to title
            }}
        >
            {sections.map((section) => (
                <AccordionItem key={section.value} value={section.value}>
                    <AccordionControl
                        /*
                                                        icon={<IconChevronDown size={30} color={
                                                            "#ff0000"
                                                        } />} // Custom icon for the arrow
                        */
                        style={{
                            display: "flex",
                            justifyContent: "center", // Center the title horizontally
                            backgroundColor: "#efefef", // Apply gray background
                            padding: "0", // Add padding to title
                            borderRadius: "10px", // Apply rounded corners
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)", // Subtle shadow for depth
                        }}
                    >
                        <div
                            className="employee-row-title"
                            style={{
                                height: "7px",
                                wordWrap: "break-word", // Allow long words to break
                                whiteSpace: "normal", // Allow text to wrap
                                textAlign: "center",
                            }}
                        >
                            <Title order={4} className="employee-row-title">
                                {section.title} [{employee.countSkills()}]
                            </Title>
                        </div>
                    </AccordionControl>
                    <AccordionPanel>
                        <EmployeeRow centerContent={true} title="">
                            {section.content}
                        </EmployeeRow>
                    </AccordionPanel>
                </AccordionItem>
            ))}
        </Accordion>
    );
};

export default EmployeeAccordion;
