// JobOfferDetailView.tsx
import React from "react";
import _isEmpty from "lodash/isEmpty";
import { EmployeeRow } from "../../../components/EmployeeRow";
import NotFound from "../../../../components/NotFound";
import { useTranslation } from "react-i18next";
import useIsOwn from "src/hooks/useIsOwn";
import EmployeeDetailHeader from "../../../components/EmployeeDetailHeader";
import CompanyBaseComponent from "../../../Company/component/CompanyBaseComponent";
import { EmployeeModel } from "../../../../models/EmployeeModel";
import Config from "../../../../classes/application/Config";
import { JobOfferStatus as JobOfferStatusType } from "../../../../types/JobOfferStatus.type";
import JobOfferDetailHeader from "../../../components/JobOfferDetailHeader";
import JobOfferActions from "./JobOfferActions"; // Import the new component

interface Props {
    employee: any; // Should be properly typed (Employee type)
}

export default function JobOfferDetailView({ employee }: Props) {
    const isOwn = useIsOwn(employee);
    const { t } = useTranslation();

    if (!employee) {
        return <NotFound label={t("employee")} />;
    }

    const { id } = employee;

    let cfg = Config.getInstance();
    let isPublic = cfg.isCurrentPublicUrl();
    let employeeModel = new EmployeeModel();
    employeeModel.setData(employee);
    let status: any = employee.status;

    let actionSubmit = {
        label: "apply_now",
        to: `/app/add/application/${id}`,
        disabled: status !== JobOfferStatusType.open,
        hasConfirm: false,
        confirmTitle: t("registration_needed"),
        confirmMessage: t("the_registration_is_needed"),
        confirmButtonConfirm: t("do_register"),
        mode_to: "route",
    };

    if (isPublic) {
        actionSubmit = {
            label: "apply_now",
            to: `/app`,
            hasConfirm: true,
            confirmTitle: t("registration_needed"),
            confirmMessage: t("the_registration_is_needed"),
            mode_to: "window",
            confirmButtonConfirm: t("do_register"),
            disabled: status !== JobOfferStatusType.open,
        };
    }

    return (
        <div className="employee-detail">
            <JobOfferDetailHeader post={employee} employee={employeeModel} isOwn={isOwn} />

            {!_isEmpty(employee?.Company) && !_isEmpty(employee?.Company?.desc) && (
                <EmployeeRow title={`${t("company")}:`}>
                    <div className="company-container" style={{ width: "100%" }}>
                        <CompanyBaseComponent company={employee?.Company} />
                        <br />
                        <br />
                    </div>
                </EmployeeRow>
            )}

            {/* Use the extracted JobOfferActions component */}
            <JobOfferActions
                isPublic={isPublic}
                actionSubmit={actionSubmit}
                isOwn={isOwn}
                id={id}
            />
        </div>
    );
}
