import { Stack } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import Actions from "src/components/Actions";
import JobOfferCard from "src/components/cards/Job.Card";
import Loader from "src/components/Loader";
import useApi, { useGET } from "src/hooks/useApi";
import JobOffer from "src/types/JobOffer.type";
import TrackingSystem from "../../../lib/Tracking/TrackingSystem";

export default function RemoveJob() {
  const navigate = useNavigate();
  const { id } = useParams();
  const api = useApi();

  const url = `res/job/${id}`;
  const { data, isLoading, error } = useGET<JobOffer>(url);
  const handleConfirm = () => {
    api.DELETE(`res/job/${id}`);

      TrackingSystem.getInstance().sendCustomEvent("job_deleted");
    navigate("/app");
  };
  const handleCancel = () => navigate(-1);

  if (error || (!isLoading && !data)) {
    navigate("/404");
  }
  return (
    <Stack align="center">
      Are you sure to remove this job?
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        <JobOfferCard jobOffer={data} />
      </Loader>
      <Actions
        actions={[
          {
            label: "cancel",
            onClick: handleCancel,
            variant: "default",
          },
          {
            label: "confirm",
            onClick: handleConfirm,
          },
        ]}
      />
    </Stack>
  );
}
