import { Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Actions from "src/components/Actions";
import { InfoList } from "src/components/InfoList";
import Loader from "src/components/Loader";
import EmployeeSection from "src/components/Sections/Employee.Section";
import useApi, { useGET } from "src/hooks/useApi";
import Admin from "src/types/Admin.type";
import TrackingSystem from "../../../lib/Tracking/TrackingSystem";

export default function RemoveAdmin() {
  const { id } = useParams();
  const api = useApi();

  const url = `res/user/${id}`;
  const { data, isLoading, error } = useGET<Admin>(url);

  const navigate = useNavigate();
  const handleConfirm = () => {
    api.DELETE(`res/user/${id}`);
      let ts = TrackingSystem.getInstance().sendCustomEvent("admin_deleted");
    navigate("/app");
  };
  const handleCancel = () => navigate(-1);

  if (error || (!isLoading && !data)) {
    navigate("/404");
  }
  const { t } = useTranslation();
  return (
    <Stack align="center">
      {t("confirm_removing_admin")}
      <Loader isLoading={isLoading} error={error} hasData={!!data}>
        <EmployeeSection employee={data} />
        <InfoList info="email"> {data.email} </InfoList>
        <InfoList info="role"> {data.role} </InfoList>
      </Loader>
      <Actions
        actions={[
          {
            label: "cancel",
            onClick: handleCancel,
            variant: "default",
          },
          {
            label: "confirm",
            onClick: handleConfirm,
          },
        ]}
      />
    </Stack>
  );
}
