import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import Avatar from "src/components/Avatar";
import {Accordion, Box, Divider, Flex, Paper, Text, Title} from "@mantine/core";
import {useTranslation} from "react-i18next";

import {getDistanceFromDate} from "src/helpers/formatDate";
import JobOffer from "src/types/JobOffer.type";
import Rate from "../../components/Rate";
import Employee from "src/types/Employee.type";
import {Row} from "../../components/Row";
import JobTitle from "../../components/badges/JobTitle";
import Availability from "../../components/Availability";
import ExpiryStatusPill from "../../components/ExpiryStatusPill";
import CompanySection from "../../components/Sections/Company.Section";
import useIsMobile from "src/hooks/useIsMobile";
import CountryList from "../../components/badges/CountryList";
import {EmployeeRow} from "./EmployeeRow";
import {EmployeeModel} from "../../models/EmployeeModel";
import ZoneList from "../../components/badges/ZoneList";
import LanguageList from "../../components/badges/LanguageList";
import VisaList from "../../components/badges/VisaList";
import FieldList from "../../components/badges/FieldList";
import Description from "../../components/Description";
import SkillSelect from "../../components/SkillSelect";
import EmployeeSkillsComponent from "../Employee/component/EmployeeSkillsComponent";
import DualItemWrapperComponent from "./DualItemWrapperComponent";
import {EmployeeAccordionRow} from "./EmployeeAccordionRow";
import EmployeeAccordion from "./EmployeeAccordion";
import { IconChevronDown } from '@tabler/icons-react'; // Or use your own icon

interface Props {
    post: Partial<JobOffer | Employee>;
    employee: EmployeeModel;

    isOwn: boolean;
}

const EmployeeDetailHeader = ({post, employee, isOwn}: Props) => {
    const {t} = useTranslation();
    const isMobile = useIsMobile();
    const availableFrom = _get(post, "availableFrom");
    const availableTo = _get(post, "availableTo");
    const profilePic = _get(post, "profilePic");
    const title = _get(post, "title");
    const name = [_get(post, "name"), _get(post, "surname", "")].join(" ");
    let forceShowpic: boolean = true;

    const sections: any = [
        {
            value: "skills",
            title: t("info_skills"),
            content: <EmployeeSkillsComponent values={employee.get_skills()}/>
        }
    ];
;

    //@ts-ignore
    let trainings: string = employee.get_training().join(", ");
    return (
        <div>
            <Title
                order={2}
                lineClamp={1}
                c={title ? "primary" : "black"}
                mx="md"
                tt="capitalize"
                ta={"center"}
            >
                {title}
                {name}
            </Title>
            {post.meta_created_at && (
                <Text
                    fw={300}
                    size="sm"
                    c="dimmed"
                    mx="md"
                    ta={"center"}
                >
                    {`${t("posted")} ${getDistanceFromDate({
                        from: post?.meta_created_at,
                    })}`}
                </Text>
            )}

            {/* Centered Avatar */}
            <Flex justify="center" align="center"
                  my="sm"
            >
                {profilePic || forceShowpic && <Avatar size={80} src={profilePic}/>}
            </Flex>


            <Row excludeDivider={true}>
                <Flex
                    direction="column"
                    flex={1}
                    gap="sm"
                    justify={"center"}
                    wrap="wrap"
                >

                    {post?.jobTitle && (
                        <Title
                            /*
                            my="md"
                            */
                            order={2}
                            miw={300}
                            ta="center"
                        >
                            <JobTitle value={post?.jobTitle} naked/>
                        </Title>
                    )}
                </Flex>
            </Row>

            <DualItemWrapperComponent>
                <EmployeeRow
                    title={t("availability") + ":"}
                    centerContent={true}

                >
                <Availability from={availableFrom} to={availableTo}/>
                </EmployeeRow>
                <EmployeeRow
                    title={t("info_place_of_work") + ":"}
                >
                    <ZoneList zones={employee.get_zones()} naked/>
                </EmployeeRow>

            </DualItemWrapperComponent>


            <DualItemWrapperComponent>
                {/* Employee Row for Zones */}
                <EmployeeRow title={t("level") + ":"} className="equal-height">
                    {t(post?.level ?? "")}

                </EmployeeRow>

                {/* Employee Row for Languages */}
                <EmployeeRow title={t("info_fields") + ":"} className="equal-height">
                    <FieldList list={employee.get_fields()}/>
                </EmployeeRow>

            </DualItemWrapperComponent>

            <DualItemWrapperComponent>
                <EmployeeRow title={t("residence") + ":"} className="equal-height"
                             centerContent={true}
                >
                    <Text mr="md">{employee.get_residentialCity()}</Text>
                    <CountryList countries={[employee.get_residentialNation()]} big/>
                </EmployeeRow>

                {/* Employee Row for Languages */}
                <EmployeeRow title={t("languages") + ":"} className="equal-height">
                    <LanguageList mt="" languages={employee.get_languages()}/>
                </EmployeeRow>
            </DualItemWrapperComponent>


            <EmployeeRow title={t("description") + ":"} Style={{
                textAlign: "justify",
                padding: "20px",
                paddingTop: "1px"
            }}>
                <Description text={employee.get_desc()}/>
            </EmployeeRow>

            {isOwn && (
                <EmployeeRow title={t("expiry_date_title") + ":"}>
                    <ExpiryStatusPill item={post} type="jobOffer"/>
                </EmployeeRow>
            )}


            <EmployeeAccordion sections={sections} employee={employee}>

            </EmployeeAccordion>

            <div style={{
                display: "none"
            }}>
                <Accordion>
                    <Accordion.Item value="skills">
                        <Accordion.Control

                            icon={<IconChevronDown size={20} color="#007bff" />} // Custom icon for the arrow

                            style={{
                                transition: 'background-color 0.3s ease, border 0.3s ease', // Smooth transition
                            }}
                        >
                            <Text>{t("info_skills") + ":"}</Text>

                        </Accordion.Control>
                        <Accordion.Panel>
                            <EmployeeRow centerContent={true} title={""}>
                                <EmployeeSkillsComponent values={employee.get_skills()}/>
                            </EmployeeRow>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </div>


            {!_isEmpty(employee.get_certifications()) && (
                <EmployeeRow title={t("certifications") + ":"}>{trainings}
                </EmployeeRow>
            )}

            {/* @ts-ignore */}
            {!_isEmpty(employee.get_training()) && (
                <EmployeeRow title={t("training") + ":"}>{
                    trainings
                }</EmployeeRow>
            )}

            <EmployeeRow title={t("info_visa_access") + ":"}>
                {/* @ts-ignore */}
                <VisaList list={employee.get_visa()} naked/>
            </EmployeeRow>

            <EmployeeRow centerContent={true} title={t("hourly_rate") + ":"}>
                <Rate
                    rate={post?.hourlyRate}
                    costsIncluded={post?.costsIncluded}
                />

            </EmployeeRow>

        </div>
    );
};

export default EmployeeDetailHeader;
