/* eslint-disable @typescript-eslint/no-unused-vars */
import {createContext} from "react";

const AuthContext = createContext({
    isLoading: true,
    email: "",
    token: "",
    name: "",
    surname: "",
    phone: "",
    origin: "",
    setEmail: (_s: string) => {
    },
    setToken: (_s: string) => {
    },
});

export default AuthContext;
