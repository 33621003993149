import React, {useState} from "react";
import {IconPlus} from "@tabler/icons-react";
import {Flex, Paper, Title} from "@mantine/core";
import {useTranslation} from "react-i18next";
import ActionButton from "../../../../components/ActionButton";
import InfoBox from "../../../../components/ui/widget/InfoBox/InfoBox";
import useIsMobile from "../../../../hooks/useIsMobile";
import Config from "../../../../classes/application/Config";
import {JobOfferStatus as JobOfferStatusType} from "../../../../types/JobOfferStatus.type";
import RequiredCompanyConfirmDialog
    from "../../../../components/ui/widget/ProgressiveRegisterConfirmDialog/RequiredCompanyConfirmDialog";
import {ConfirmDialogParams} from "../../../../components/ui/widget/ConfirmDialog/ConfirmDialogParams";
import Application from "../../../../classes/Application";

const EmployeeListTopBarComponent = () => {
    const {t} = useTranslation();
    const isMobile = useIsMobile();
    const direction = isMobile ? "column" : "row";
    const center = true;
    const infoboxCode = "infobox_developers";

    let params = new ConfirmDialogParams();

    const actionSubmit = {
        label: t("apply_now"),
        to: `/app`,
        hasConfirm: true,
        confirmTitle: t("registration_needed"),
        confirmMessage: t("the_registration_is_needed"),
        mode_to: "window",
        confirmButtonConfirm: t("do_register"),
        disabled: status !== JobOfferStatusType.open,
    };

    const [isDialogOpen, setIsDialogOpen] = useState(false); // State to control the dialog

    const style = {
        background: "linear-gradient(90deg, var(--mantine-color-primary-filled), transparent)",
        boxShadow: "none",
        margin: "0px",
    };

    const stylep = {
        background: "linear-gradient(90deg, var(--mantine-color-primary-filled), transparent)",
        boxShadow: "none",
        margin: "0px",
    };

    const handleActionButtonClick = () => {
        const cfg = Config.getInstance();
        //    alert(params.getCloseModal());
        // let fun=params.getCloseModal();
        let app = Application.getInstance();
        app.getUser()

        let fun = params.getOpenModal();
        if (cfg.hasProgressiveRegister() && !app.hasCompany()) {

            if (fun) {
                fun();
            }
            //     setIsDialogOpen(true); // Open the confirmation dialog if progressive registration is needed
        } else {
            window.location.href = actionSubmit.to; // Go to the route directly if no confirmation
        }
        //    alert(isDialogOpen);
    };

    const handleConfirm = () => {
        window.location.href = actionSubmit.to; // Proceed if confirmed
        setIsDialogOpen(false); // Close the dialog after confirmation
    };

    const handleCancel = () => {
        setIsDialogOpen(false); // Close the dialog if cancelled
    };

    return (
        <>
            <Paper c="white" px="md" py="xs" mb="xl" style={stylep}>
                <Flex justify="space-between" align="center" direction={direction} style={{flex: 1}}>
                    <Flex direction="row" align="center" my="md" gap="xs">
                        {typeof infoboxCode === "string" && (
                            <InfoBox
                                code={infoboxCode}
                                title={t("developers_info")}
                                content={t("info_content")}
                            />
                        )}

                        <Title order={4} tt="uppercase">
                            {t("my_employees")}
                        </Title>
                    </Flex>
                    <Flex direction={direction} justify={center || isMobile ? "center" : "end"} gap="sm" wrap="wrap">
                        <ActionButton
                            isReady={true}
                            to={"/app/add/employee"}
                            label={t("add_new")}
                            Icon={IconPlus}
                            onClick={handleActionButtonClick} // Attach the click handler
                        />
                    </Flex>
                </Flex>
            </Paper>

            {/* Confirmation Dialog */}
            <RequiredCompanyConfirmDialog params={
                params
            }
                                          isOpen={isDialogOpen}/>
        </>
    );
};

export default EmployeeListTopBarComponent;
