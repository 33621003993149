// @ts-nocheck
import { useEffect } from 'react';
import Application from "../classes/Application";
import {UTMHandler} from "../lib/Tracking/UTMHandler";

const usePageView = (pageTitle: any, context: any = "A8App") => {
    useEffect(() => {
        const checkGtagReady = () => {
            if (window.gtag_ready === 1 && window.gtag) {
                const now = Date.now();

                if (!window.last_pageview_fired) {
                    window.last_pageview_fired = 0;
                }

                if (now - window.last_pageview_fired < 2000) return;
                window.last_pageview_fired = now;

                let app = Application.getInstance();
                let isAuth = app.isAuth();
                let auth: string = isAuth ? "1" : "0";

                const utmHandler = UTMHandler.getInstance();
                const utmParams = utmHandler.getUTMParams();

                window.gtag('event', 'page_view', {
                    page_title: `(${context}) - ${pageTitle}`,
                    page_location: window.location.href,
                    page_path: window.location.pathname,
                    app_is_auth: auth,
                    event_category: context,
                    category: context,
                    ...utmParams
                });
            } else {
                setTimeout(checkGtagReady, 4000);
            }
        };

        checkGtagReady();
    }, [pageTitle, context]);
};

export default usePageView;
